import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-loading-progress-bar',
  templateUrl: './loading-progress-bar.component.html',
  styleUrls: ['./loading-progress-bar.component.scss'],
})
export class LoadingProgressBarComponent {
  @Input() percentDownload: number;
  @Input() isSuccessDownload: boolean;
  @Input() textStatus: string | null;
  @Output() dismiss = new EventEmitter<void>();
  @Output() submitted = new EventEmitter<void>();

  onDismissClick(): void {
    this.dismiss.emit();
  }

  onSubmitClick(): void {
    this.submitted.emit();
  }
}
