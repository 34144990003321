import { Announcement } from 'src/app/modules/new-feed/shared/news-feed.models';

export class ShowAnnouncementDetail {
  static readonly type = '[Announcement] ShowAnnouncementDetail';
  constructor(public announcement: Announcement) {}
}

export class ClearSelectAnnouncement {
  static readonly type = '[Announcement] ClearSelectAnnouncement';
  constructor() {}
}
