import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { ProfileService } from '../../../../modules/profile/shared/profile.service';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { lastValueFrom } from 'rxjs';
import { AlertService } from '@core/services/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-signature-upload',
  templateUrl: './signature-upload.component.html',
  styleUrls: ['./signature-upload.component.scss'],
})
export class SignatureUploadComponent {
  @Input() cancelText = 'APPROVAL.BACK';
  @Input() isModal = false;
  @Output() next = new EventEmitter<File>();
  @Output() back = new EventEmitter<void>();

  isLoading = false;

  private profileService = inject(ProfileService);
  private alert = inject(AlertService);
  private translate = inject(TranslateService);

  async filePhotoChangeEvent(event: Event) {
    const target = event.target as HTMLInputElement;
    if (!target.files || target.files.length === 0) {
      return;
    }
    await this.showExamplePreview(target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(target.files[0]);
    reader.onload = () => {};
    target.value = '';
  }

  async onFileDrop(event: NgxFileDropEntry[]) {
    for (const droppedFile of event) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry =
          droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.showExamplePreview(file);
        });
      }
    }
  }

  checkFileSize(file: File): boolean {
    if (file != null && file.size / (1000 * 1000) >= 45) {
      this.alert.error(
        this.translate.instant(
          'MEMOS.Please upload file less than 45 MB',
        ),
      );
      return false;
    }
    return true;
  }

  async showExamplePreview(file: File) {
    if (!this.checkFileSize(file)) {
      return;
    }
    this.isLoading = true;
    try {
      const fd = new FormData();
      fd.append('file ', file);
      const res: Blob = await lastValueFrom(
        this.profileService.removeBackground(fd),
      );
      const signImage = new File([res], 'image', {
        type: res.type,
      });
      this.isLoading = false;
      this.next.emit(signImage);
    } catch (err) {
      this.isLoading = false;
      console.error(err);
    }
  }
}
