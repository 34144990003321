<div class="position-relative img-thumbnail d-flex align-items-center justify-content-center">
  <image-cropper #imageCropper
      class="cropper"
      [imageFile]="signImageBlob"
      [imageChangedEvent]="signImageBlob"
      [maintainAspectRatio]="false"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (loadImageFailed)="loadImageFailed()"
      [cropper]="cropper"
      (cropperReady)="onCropperReady($event)"
      format="png"
      output="base64"
      outputType="both">
  </image-cropper>
  <div class="position-btn-sign-now">
    <button class="btn btn-sign-now fs-base-l4 fw-semibold text-gray-neutrals "
        (click)="_back()">
      {{ againText | translate }}
    </button>
  </div>
  <div class="position-btn-undo-redo">
    <button class="btn btn-undo-redo"
        (click)="undoCropper()"
        ngbTooltip="{{'MEMOS.UNDO' | translate}}"
        [disabled]="disabledUndo">
      <ng-container *ngTemplateOutlet="svgIcon;
                  context: {iconPath:'assets/images/icons/reset.svg'}"></ng-container>
    </button>
    <button class="btn btn-undo-redo mt-3"
        (click)="redoCropper()"
        ngbTooltip="{{'MEMOS.REDO' | translate}}"
        [disabled]="redoStack.length <= 0">
      <ng-container *ngTemplateOutlet="svgIcon;
                  context: {iconPath:'assets/images/icons/redo.svg'}"></ng-container>
    </button>
  </div>
  <div class="fs-base-l7 fs-base-md-l4 fw-medium text-red-pig-blood mt-md-2 mt-1 sub-text">
    {{ 'SELECT-SIGN-METHOD.For JPG file the system will automatically remove background' | translate }}
  </div>
</div>

<div class="d-flex justify-content-end mt-4 text-end"
    [ngClass]="{'pt-2 mb-btn-bottom': !isModal}">
  <button class="btn btn-std btn-std-2ry fs-base-l0 fw-semibold justify-content-center"
      [ngClass]="isModal ? 'btn-h-sm-res fs-base-l4 mx-1 py-base-l8 px-base-l4 btn-width': 'me-l5'"
      type="button"
      (click)="_back()">
    {{ 'APPROVAL.BACK' | translate }}
  </button>
  <button class="btn btn-std fs-base-l0 btn-std-prim"
      [ngClass]="{'btn-h-sm-res fs-base-l4 mx-1 py-base-l8 px-base-l4 btn-width': isModal}"
      type="button"
      (click)="_next()">
    {{ "MEMOS.NEXT" | translate }}
  </button>
</div>

<ng-template #svgIcon
  let-iconPath="iconPath">
  <svg-icon [src]="iconPath"
      [svgStyle]="{ 'width.px': 20, 'height.px': 30, 'stroke': '#1958D3' }"
      class="pointer">
  </svg-icon>
</ng-template>