<ng-select [items]="people$ | async"
    bindLabel="full_name"
    [loading]="peopleLoading"
    placeholder="{{'MEMOS.SELECT-APPROVER'|translate}}"
    [clearable]="clearable"
    [disabled]="disabled"
    [(ngModel)]="approval"
    (change)="onDropdownChange($event);"
    [typeahead]="peopleInput$"
    [minTermLength]="2"
    typeToSearchText="{{ 'user.type-search' | translate }}"
    [notFoundText]="'SELECT.No items found'|translate">
</ng-select>
<ng-container *ngIf="ddocEnable">
  <app-signing-law-section-radio [signerId]="$any(approval?.person)"
      [certStatusSec26]="approval?.m26_certificate_status"
      [certStatusSec28]="approval?.m28_certificate_status"
      [(ddocLawSection)]="ddocLawSection"
      (ddocLawSectionChange)="ddocLawSectionChange.emit($event)"
      [freeLawSelection]="freeLawSelection"
      [loaAllowEdit]="loaAllowEdit"
      (signerRejected)="approvalChange.emit({ person: null })">
  </app-signing-law-section-radio>
</ng-container>
