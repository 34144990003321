<ng-template #nextApprovalPopup>
  <div class="modal-header">
    <h4 class="fs-lg-u7 fs-u3 fw-bold mb-0 mx-2">
      {{ header | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <svg class="text-blue-sapphire"
          height="56"
          width="56"
          fill="currentColor">
        <use height="56"
            width="56"
            href="assets/images/icons/marks/check-circle-fill.svg#check-circle-fill"
            [style.fill]="'#2FB551'">
        </use>
      </svg>
      <div class="my-u8 fw-medium">
        {{ textMessage | translate }}
      </div>
      <button (click)="previousPage()"
          type="button"
          class="btn btn-std btn-h-sm-res btn-std-prim fs-l4">
        {{ 'APPROVAL.CONTINUE-APPROVE' | translate }}
      </button>
      <div class="pointer fs-l4 fw-semibold text-gray-neutrals mt-l4"
          (click)="goToMemoDetail()">
        {{ 'APPROVAL.GO-TO-CHECK-DOCUMENT' | translate }}
      </div>
    </div>
  </div>
</ng-template>
