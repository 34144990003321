import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Action,
  createPropertySelectors,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import {
  ClearState,
  GetUserInfo,
  Login,
  Logout,
  SaveUserDetail,
} from './auth.actions';
import { AuthResponse, AuthStateModel } from './auth.model';
import { Observable } from 'rxjs';
import { NavbarService } from '@core/components/navbar/navbar.service';
import { Nullable } from '@shared/models/common.model';
import { Person } from '@shared/models/user.model';
import { environment } from 'src/environments/environment';

const defaultData: Nullable<AuthStateModel> = {
  refresh: null,
  access: null,
  is_register: null,
  is_password_valid: null,
  role: null,
  session_name: null,
  user_info: null,
};

@State<Nullable<AuthStateModel>>({
  name: 'auth',
  defaults: defaultData,
})
@Injectable({
  providedIn: 'root',
})
export class AuthState {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private spinner: SpinnerService,
    private store: Store,
    private zone: NgZone,
    private navbar: NavbarService,
    private activated: ActivatedRoute,
  ) {}

  @Selector()
  static token(state: AuthStateModel): AuthStateModel {
    return state;
  }

  setUserDataToStorage(result: Person): void {
    localStorage.setItem('username', result.user.username);
    localStorage.setItem('photoUrl', result.photo || '');
    localStorage.setItem('pid', result.id + '');
  }

  updateLocalStorage(result: AuthResponse): void {
    localStorage.setItem('session_name', result.session_name);
    localStorage.setItem('currentUser', result.access);
    localStorage.setItem('refresh', result.refresh.replace(`"`, ''));
    localStorage.setItem(
      'is_register',
      result.is_register.toString(),
    );
    localStorage.setItem(
      'is_password_valid',
      result.is_password_valid.toString(),
    );
  }

  @Action(Login)
  login(
    { patchState }: StateContext<AuthStateModel>,
    { payload }: any,
  ): Observable<AuthResponse> {
    this.spinner.show();
    return this.authService
      .login(payload.username, payload.password)
      .pipe(
        tap((result: AuthResponse) => {
          result.role = JSON.parse(result.role)[0];
          patchState({
            refresh: result.refresh,
            access: result.access,
            is_register: result.is_register,
            is_password_valid: result.is_password_valid,
            session_name: result.session_name,
            role: result.role,
          });
          this.updateLocalStorage(result);
          this.store.dispatch(GetUserInfo);
          if (!result.is_register) {
            this.zone.run(() => {
              this.router.navigate(['/register']);
            });
            return;
          }
          if (!result.is_password_valid) {
            this.zone.run(() => {
              this.router.navigate(['/reset-password']);
            });
            return;
          }
          if (result.role !== 'General') {
            this.zone.runOutsideAngular(() => {
              this.zone.run(() => {
                this.router.navigate(['/users']);
              });
            });
          } else {
            this.zone.runOutsideAngular(() => {
              this.activated.queryParams.subscribe((queryParams) => {
                if (
                  this.router.url.includes('/login') ||
                  this.router.url.includes('/reset-password') ||
                  this.router.url.includes('/register')
                ) {
                  let navigatePath = '/memos';
                  const navigateQueryParams: {
                    [key: string]: string;
                  } = {};

                  if (queryParams['returnUrl']) {
                    const { returnUrl: returnUrlStr } = queryParams;
                    const returnUrl =
                      this.convertUrlStringToInstance(returnUrlStr);
                    if (returnUrl) {
                      navigatePath = returnUrl.pathname;
                      returnUrl.searchParams.forEach((value, key) => {
                        navigateQueryParams[key] = value;
                      });
                    }
                  }

                  this.zone.run(() => {
                    this.router.navigate([navigatePath], {
                      queryParams: navigateQueryParams,
                    });
                  });
                }
              });
            });
          }
        }),
        finalize(() => this.spinner.hide()),
      );
  }

  @Action(Logout)
  logout({ setState }: StateContext<Nullable<AuthStateModel>>) {
    if (localStorage.getItem('currentUser')) {
      this.authService.logout().subscribe();
    }
    localStorage.clear();
    sessionStorage.clear();
    this.navbar.setActiveSidebar(false);
    setState(defaultData);
  }

  @Action(ClearState)
  clearState({ setState }: StateContext<Nullable<AuthStateModel>>) {
    if (localStorage.getItem('currentUser')) {
      this.authService.logout().subscribe();
    }
    localStorage.clear();
    setState(defaultData);
  }

  @Action(GetUserInfo)
  getUser({
    setState,
  }: StateContext<AuthStateModel>): Observable<Person> {
    return this.authService.getUserInfo().pipe(
      tap((user) => {
        setState({
          refresh: localStorage.getItem('currentUser') || '',
          access: localStorage.getItem('currentUser') || '',
          is_register: user.is_register,
          is_password_valid: user.is_password_valid,
          is_reset_password: user.is_reset_password,
          session_name: localStorage.getItem('session_name') || '',
          role: user.role?.name || '',
          user_info: user,
        });
        this.setUserDataToStorage(user);
      }),
    );
  }

  @Action(SaveUserDetail)
  saveItem(
    { patchState }: StateContext<AuthStateModel>,
    { value, name }: { value: any; name: string },
  ): void {
    patchState({
      [name]: value,
    });
  }

  private convertUrlStringToInstance(urlStr: string): URL | null {
    let url: URL | null = null;
    try {
      url = new URL(urlStr, environment.baseUrl);
    } catch (err) {
      url = null;
    }
    return url;
  }
}

export class AuthSelectors {
  static getSlices =
    createPropertySelectors<AuthStateModel>(AuthState);
}
