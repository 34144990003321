<div *ngIf="isSuccessDownload"
    class="modal-body p-5">
  <div [style.color]="'#1958D3'"
      [style.font-size.px]="40"
      class="col-12 text-center fw-semibold">
    {{ textStatus ? (textStatus|translate) : (percentDownload) + '%' }}
  </div>
  <div class="col-12 text-center p-3">
    {{ 'EXPORT.Please wait while the page is loading' | translate }}
  </div>
  <ngb-progressbar [value]="percentDownload"
      height="1rem">
  </ngb-progressbar>
</div>
<div *ngIf="!isSuccessDownload">
  <app-modal-confirmation-std4-skin [dismissText]="'COMMON.Cancel' | translate"
      [header]="'EXPORT.Download failed' | translate"
      [submitText]=" 'EXPORT.Download Again' | translate"
      [title]="'EXPORT.Oops! An error has occurred' | translate"
      [subtitle]="'EXPORT.Your program failed to download. Please try again' | translate"
      (dismiss)="onDismissClick()"
      (submitted)="onSubmitClick()">
    <ng-template>
      <svg class="text-blue-sapphire"
          height="56"
          width="56"
          fill="currentColor">
        <use height="56"
            width="56"
            href="assets/images/icons/alert/exclamation-circle-fill.svg#exclamation-circle-fill"
            [style.fill]="'#f53636'"></use>
      </svg>
    </ng-template>
  </app-modal-confirmation-std4-skin>
</div>