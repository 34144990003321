export interface DrfList<T = any, QueryDisplayValue = LabelDisplay> {
  count: number;
  next: string;
  previous: string;
  results: Array<T>;
  no_data: boolean;
  query_params_display?:
    | QueryParamsDisplay<QueryDisplayValue>[]
    | null;
}

export interface CeleryTaskStatus {
  _state: CeleryImportState;
  percent?: number;

  [key: string]: any;
}

export interface LabelDisplay {
  display: string;
  display_en: string;
}

export interface QueryParamsDisplay<Value = LabelDisplay> {
  key: string;
  key_display: string;
  key_display_en: string;
  value: Value;
}

export enum CeleryImportState {
  Pending = 'PENDING',
  Progress = 'PROGRESS',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}
