import {
  FormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { PersonNameDetail } from '@shared/models/user.model';
import { customAlphabet, nanoid } from 'nanoid';
import { PreviewEFormAttachment } from '../../memos/model/memo.model';

export enum EFormTypes {
  row = 'row',
  empty = 'empty',
  heading = 'heading',
  text = 'text',
  richtext = 'richtext',
  email = 'email',
  date = 'date',
  image = 'image',
  phone = 'phone',
  checkbox = 'checkbox',
  radio = 'radio',
  dropdown = 'dropdown',
  file = 'file',
  table = 'table',
}

export enum EFormTableColumnTypes {
  text = 'text',
  textarea = 'textarea',
  checkbox = 'checkbox',
  radio = 'radio',
  dropdown = 'dropdown',
  email = 'email',
  date = 'date',
  number = 'number',
  phone = 'phone',
  currency = 'currency',
}

export interface GetEFormListParams {
  name: string;
  page: number;
  limit: number;
}

export function generateFileKey(): string {
  return customAlphabet('1234567890abcdef', 10)();
}

export class EForm {
  id?: number;
  name: string;
  name_en: string;
  template: EFormTemplate;
  attachments: (EFormAttachment | PreviewEFormAttachment)[];
  created_user?: PersonNameDetail;
  updated_user?: PersonNameDetail;
  created_at?: Date;
  updated_at?: Date;

  constructor(eForm?: Partial<EForm>, applyAttachments = true) {
    this.template = new EFormTemplate(eForm?.template);
    this.id = eForm?.id;
    this.name = eForm?.name ?? '';
    this.name_en = eForm?.name_en ?? '';
    applyAttachments
      ? this.applyAttachments(eForm?.attachments ?? [])
      : (this.attachments = eForm?.attachments ?? []);
    this.created_user = eForm?.created_user;
    this.updated_user = eForm?.updated_user;
    this.created_at = eForm?.created_at;
    this.updated_at = eForm?.updated_at;
  }

  applyAttachments(
    attachments: (EFormAttachment | PreviewEFormAttachment)[],
  ): void {
    const attachmentsMap = new Map(
      attachments.map((attachment) => [
        attachment.file_key,
        attachment,
      ]),
    );

    const updateFileFields = (field: EFormFileElements): void => {
      const attachment = attachmentsMap.get(field.file_key);
      if (attachment) {
        const file =
          'memo_attachment' in attachment
            ? attachment.memo_attachment.file
            : attachment.file;
        field.file = file;
        if (attachment.is_image) {
          field.previewFile = file;
        }
      }
    };

    // Update file fields with corresponding attachment data
    this.template.rows = this.template.rows.map((row) => ({
      ...row,
      children: row.children.map((field) => {
        if (field instanceof EFormImageElement) {
          updateFileFields(field);
        }
        return field;
      }),
    }));

    // assign
    this.attachments = attachments;
  }

  getAllFileData(newOnly = true): EFormFileData[] {
    // filter all file elements
    let fileElements: EFormFileElements[] =
      this.template.rows.flatMap(
        (row) =>
          row.children.filter(
            (field) => field instanceof EFormImageElement,
          ) as EFormFileElements[],
      );

    // filter all newly uploaded file elements
    if (newOnly) {
      fileElements = fileElements.filter(
        (fileElement) => typeof fileElement.file !== 'string',
      );
    }

    // helper function to transform field to fileData
    const getFileData = (field: EFormFileElements): EFormFileData => {
      return {
        file: field.file,
        type: field instanceof EFormImageElement ? 'image' : 'file',
        file_key: field.file_key,
      };
    };

    return fileElements.map((fileElement) =>
      getFileData(fileElement),
    );
  }

  validate(mode: 'admin' | 'user' = 'user'): boolean {
    return this.template.rows
      .flatMap((row) =>
        row.children.map((field) => field.validate(mode)),
      )
      .every((isValid) => isValid);
  }

  areAllFieldsValid(): boolean {
    return this.template.rows.every((row) =>
      row.children.every((field) => field.hasNoErrors()),
    );
  }

  getDto(): EForm {
    return {
      ...this,
      template: {
        ...this.template,
        rows: this.template.rows.map((row) => ({
          ...row,
          children: row.children.map((field) => field.getDto()),
        })),
      },
    };
  }
}

export class EFormTemplate {
  rows: EFormTemplateRow[];

  constructor(eFormTempl?: Partial<EFormTemplate>) {
    if (!eFormTempl?.rows?.length) {
      this.rows = [new EFormTemplateRow()];
      return;
    }

    this.rows = eFormTempl.rows.map(
      (row) => new EFormTemplateRow(row),
    );
  }
}

export class EFormTemplateRow {
  type = EFormTypes.row;
  children: EFormElement[];

  constructor(EFormTemplateRow?: Partial<EFormTemplateRow>) {
    if (!EFormTemplateRow?.children?.length) {
      this.children = [new EFormEmptyElement()];
      return;
    }

    this.children = EFormTemplateRow.children.map((field) => {
      return createElementByType(field.type, field);
    });
  }
}

export function createElementByType(
  type: EFormTypes,
  element?: EFormElement,
): EFormElement {
  const cloneElement = structuredClone(element);
  switch (type) {
    case EFormTypes.heading:
      return new EFormHeadingElement(
        cloneElement as EFormHeadingElement,
      );
    case EFormTypes.text:
      return new EFormTextElement(cloneElement as EFormTextElement);
    case EFormTypes.richtext:
      return new EFormRichtextElement(
        cloneElement as EFormRichtextElement,
      );
    case EFormTypes.email:
      return new EFormEmailElement(cloneElement as EFormEmailElement);
    case EFormTypes.date:
      return new EFormDateElement(cloneElement as EFormDateElement);
    case EFormTypes.image:
      return new EFormImageElement(cloneElement as EFormImageElement);
    case EFormTypes.phone:
      return new EFormPhoneElement(cloneElement as EFormPhoneElement);
    case EFormTypes.checkbox:
      return new EFormCheckboxElement(
        cloneElement as EFormCheckboxElement,
      );
    case EFormTypes.radio:
      return new EFormRadioElement(cloneElement as EFormRadioElement);
    case EFormTypes.dropdown:
      return new EFormDropdownElement(
        cloneElement as EFormDropdownElement,
      );
    case EFormTypes.table:
      return new EFormTableElement(cloneElement as EFormTableElement);
    default:
      return new EFormEmptyElement();
  }
}

export class EFormElementBase {
  errors: ValidationErrors = {};

  validate(mode: 'admin' | 'user' = 'user'): boolean {
    this.errors = {};

    if (mode === 'admin') {
      this.validateForAdmin();
    }

    if (mode === 'user') {
      this.validateForUser();
    }

    return this.hasNoErrors();
  }

  protected validateForAdmin(): void {}

  protected validateForUser(): void {}

  protected isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  getDto<T>(): T {
    return {} as T;
  }

  hasNoErrors(): boolean {
    return Object.keys(this.errors).length === 0;
  }
}

export class EFormEmptyElement extends EFormElementBase {
  type = EFormTypes.empty;

  override getDto<EFormEmptyElement>(): EFormEmptyElement {
    return { type: this.type } as EFormEmptyElement;
  }
}

export class EFormHeadingElement extends EFormElementBase {
  type = EFormTypes.heading;

  name: string; // title
  value: string; // description

  size: 'default' | 'large' | 'small';

  // no need for api
  label = 'Heading';
  icon = 'far fa-heading';
  fullWidth = true;

  constructor(element?: Partial<EFormHeadingElement>) {
    super();

    this.name = element?.name ?? '';
    this.value = element?.value ?? '';

    this.size = element?.size ?? 'default';
  }

  override validateForAdmin(): void {
    if (!this.name?.trim().length) {
      this.errors['name'] = 'Heading is required';
    }
  }

  override getDto<EFormHeadingElement>(): EFormHeadingElement {
    return {
      type: this.type,
      name: this.name,
      value: this.value,
      size: this.size,
    } as EFormHeadingElement;
  }
}

export class EFormTextElement extends EFormElementBase {
  type = EFormTypes.text;

  name: string;
  value: string;

  showTitle: boolean;
  require: boolean;
  placeholder: string;
  min: number;
  max: number;

  // no need for api
  label = 'Text';
  icon = 'far fa-text';

  constructor(element?: Partial<EFormTextElement>) {
    super();

    this.name = element?.name ?? '';
    this.value = element?.value ?? '';

    this.showTitle = element?.showTitle ?? true;
    this.require = element?.require ?? false;
    this.placeholder = element?.placeholder ?? '';
    this.min = element?.min ?? 0;
    this.max = element?.max ?? 0;
  }

  override validateForAdmin(): void {
    if (this.showTitle && !this.name?.trim().length) {
      this.errors['name'] = 'Title is required';
    }
    if (this.value?.length) {
      this.valueMinMaxValidation();
    }
  }

  override validateForUser(): void {
    if (this.require && !this.value?.trim().length) {
      this.errors['value'] = 'Value is required';
    }
    this.valueMinMaxValidation();
  }

  private valueMinMaxValidation(): void {
    if (this.min && this.value.trim().length < this.min) {
      this.errors['value'] = 'Value must be greater than ' + this.min;
    }
    if (this.max && this.value.trim().length > this.max) {
      this.errors['value'] = 'Value must be less than ' + this.max;
    }
    if (
      this.min &&
      this.max &&
      (this.value.trim().length < this.min ||
        this.value.trim().length > this.max)
    ) {
      this.errors[
        'value'
      ] = `Value must be between ${this.min} and ${this.max}`;
    }
  }

  override getDto<EFormTextElement>(): EFormTextElement {
    return {
      type: this.type,
      name: this.name,
      value: this.value,
      showTitle: this.showTitle,
      require: this.require,
      placeholder: this.placeholder,
      min: this.min,
      max: this.max,
    } as EFormTextElement;
  }
}

export class EFormRichtextElement extends EFormElementBase {
  type = EFormTypes.richtext;

  name: string;
  value: string;

  showTitle: boolean;

  // no need for api
  label = 'Richtext';
  icon = 'far fa-paragraph';
  fullWidth = true;

  constructor(element?: Partial<EFormRichtextElement>) {
    super();

    this.name = element?.name ?? '';
    this.value = element?.value ?? '';

    this.showTitle = element?.showTitle ?? true;
  }

  override validateForAdmin(): void {
    if (this.showTitle && !this.name?.trim().length) {
      this.errors['name'] = 'Title is required';
    }
  }

  override getDto<EFormRichtextElement>(): EFormRichtextElement {
    return {
      type: this.type,
      name: this.name,
      value: this.value,
      showTitle: this.showTitle,
    } as EFormRichtextElement;
  }
}

export class EFormEmailElement extends EFormElementBase {
  type = EFormTypes.email;

  name: string;
  value: string;

  showTitle: boolean;
  require: boolean;
  placeholder: string;

  // no need for api
  label = 'Email';
  icon = 'far fa-envelope';

  constructor(element?: Partial<EFormEmailElement>) {
    super();

    this.name = element?.name ?? '';
    this.value = element?.value ?? '';

    this.showTitle = element?.showTitle ?? true;
    this.require = element?.require ?? false;
    this.placeholder = element?.placeholder ?? '';
  }

  override validateForAdmin(): void {
    if (this.showTitle && !this.name?.trim().length) {
      this.errors['name'] = 'Title is required';
    }
    if (this.value?.length && !this.isValidEmail(this.value)) {
      this.errors['value'] = 'Enter a valid email';
    }
  }

  override validateForUser(): void {
    if (this.require && !this.value?.trim().length) {
      this.errors['value'] = 'Value is required';
    }
    if (this.value?.length && !this.isValidEmail(this.value)) {
      this.errors['value'] = 'Enter a valid email';
    }
  }

  override getDto<EFormEmailElement>(): EFormEmailElement {
    return {
      type: this.type,
      name: this.name,
      value: this.value,
      showTitle: this.showTitle,
      require: this.require,
      placeholder: this.placeholder,
    } as EFormEmailElement;
  }
}

export class EFormDateElement extends EFormElementBase {
  type = EFormTypes.date;

  name: string;
  value: string | null;

  showTitle: boolean;
  require: boolean;
  format: string;
  placeholder: string;

  // no need for api
  label = 'Date';
  icon = 'far fa-calendar';

  constructor(element?: Partial<EFormDateElement>) {
    super();

    this.name = element?.name ?? '';
    this.value = element?.value ?? null;

    this.showTitle = element?.showTitle ?? true;
    this.require = element?.require ?? false;
    this.format = element?.format ?? 'EN:%-d/%-m/%Y';
    this.placeholder = element?.placeholder ?? '';
  }

  override validateForAdmin(): void {
    if (this.showTitle && !this.name?.trim().length) {
      this.errors['name'] = 'Title is required';
    }
  }

  override validateForUser(): void {
    if (this.require && !this.value?.trim().length) {
      this.errors['value'] = 'Value is required';
    }
  }

  override getDto<EFormDateElement>(): EFormDateElement {
    return {
      type: this.type,
      name: this.name,
      value: this.value,
      showTitle: this.showTitle,
      require: this.require,
      format: this.format,
      placeholder: this.placeholder,
    } as EFormDateElement;
  }
}

export class EFormImageElement extends EFormElementBase {
  type = EFormTypes.image;

  name: string;
  file_key: string;
  file_name: string;

  showTitle: boolean;
  require: boolean;
  scale: number;
  align: 'left' | 'center' | 'right';

  // no need for api
  file: File | string | null;
  previewFile: ArrayBuffer | string | null;
  label = 'Image';
  icon = 'far fa-image';
  fullWidth = true;

  constructor(
    element?: Partial<EFormImageElement>,
    createNewKey = false,
  ) {
    super();

    this.name = element?.name ?? '';
    this.file_name = element?.file_name ?? '';

    const generatedKey = generateFileKey();
    this.file_key = createNewKey
      ? generatedKey
      : element?.file_key ?? generatedKey;

    this.showTitle = element?.showTitle ?? true;
    this.require = element?.require ?? false;
    this.scale = element?.scale ?? 100;
    this.align = element?.align ?? 'center';

    this.file = element?.file ?? null;
    this.previewFile = element?.previewFile ?? null;
  }

  override validateForAdmin(): void {
    if (this.showTitle && !this.name?.trim().length) {
      this.errors['name'] = 'Title is required';
    }
  }

  override validateForUser(): void {
    if (this.require && !this.file) {
      this.errors['file'] = 'This field is required';
    }
  }

  override getDto<EFormImageElement>(): EFormImageElement {
    return {
      type: this.type,
      name: this.name,
      file_key: this.file_key,
      file_name: this.file_name,
      showTitle: this.showTitle,
      require: this.require,
      scale: this.scale,
      align: this.align,
    } as EFormImageElement;
  }
}

export class EFormPhoneElement extends EFormElementBase {
  type = EFormTypes.phone;

  name: string;
  value: string;

  showTitle: boolean;
  require: boolean;
  placeholder: string;

  // no need for api
  label = 'Phone';
  icon = 'far fa-phone';

  constructor(element?: Partial<EFormPhoneElement>) {
    super();

    this.name = element?.name ?? '';
    this.value = element?.value ?? '';

    this.showTitle = element?.showTitle ?? true;
    this.require = element?.require ?? false;
    this.placeholder = element?.placeholder ?? '';
  }

  override validateForAdmin(): void {
    if (this.showTitle && !this.name?.trim().length) {
      this.errors['name'] = 'Title is required';
    }
  }

  override validateForUser(): void {
    if (this.require && !this.value?.trim().length) {
      this.errors['value'] = 'Value is required';
    }
  }

  override getDto<EFormPhoneElement>(): EFormPhoneElement {
    return {
      type: this.type,
      name: this.name,
      value: this.value,
      showTitle: this.showTitle,
      require: this.require,
      placeholder: this.placeholder,
    } as EFormPhoneElement;
  }
}

export class EFormCheckboxElement extends EFormElementBase {
  type = EFormTypes.checkbox;

  name: string;
  value: EFormElementOption[];

  showTitle: boolean;
  require: boolean;

  // no need for api
  label = 'Checkbox';
  icon = 'far fa-check-square';

  constructor(element?: Partial<EFormCheckboxElement>) {
    super();

    this.name = element?.name ?? '';
    this.value = element?.value ?? [
      {
        text: '',
        selected: true,
      },
    ];

    this.showTitle = element?.showTitle ?? true;
    this.require = element?.require ?? false;
  }

  override validateForAdmin(): void {
    if (this.showTitle && !this.name?.trim().length) {
      this.errors['name'] = 'Title is required';
    }
    if (
      this.value &&
      !this.value.some((option) => option.text?.trim().length)
    ) {
      this.errors['value'] = 'Some option names are empty';
    }
    if (
      this.value &&
      this.value.map((option) => option.text).length >
        new Set(this.value.map((option) => option.text)).size
    ) {
      this.errors['value'] = 'Some option names are duplicated';
    }
  }

  override validateForUser(): void {
    if (
      this.require &&
      !this.value?.some((option) => option.selected)
    ) {
      this.errors['value'] = 'Value is required';
    }
  }

  override getDto<EFormCheckboxElement>(): EFormCheckboxElement {
    return {
      type: this.type,
      name: this.name,
      value: this.value,
      showTitle: this.showTitle,
      require: this.require,
    } as EFormCheckboxElement;
  }
}

export class EFormRadioElement extends EFormElementBase {
  type = EFormTypes.radio;

  name: string;
  value: EFormElementOption[];

  showTitle: boolean;
  require: boolean;

  // no need for api
  label = 'Radio Button';
  icon = 'far fa-dot-circle';

  constructor(element?: Partial<EFormCheckboxElement>) {
    super();

    this.name = element?.name ?? '';
    this.value = element?.value ?? [
      {
        text: '',
        selected: true,
      },
    ];

    this.showTitle = element?.showTitle ?? true;
    this.require = element?.require ?? false;
  }

  override validateForAdmin(): void {
    if (this.showTitle && !this.name?.trim().length) {
      this.errors['name'] = 'Title is required';
    }
    if (
      this.value &&
      !this.value.some((option) => option.text?.trim().length)
    ) {
      this.errors['value'] = 'Some option names are empty';
    }
    if (
      this.value &&
      this.value.map((option) => option.text).length >
        new Set(this.value.map((option) => option.text)).size
    ) {
      this.errors['value'] = 'Some option names are duplicated';
    }
  }

  override validateForUser(): void {
    if (
      this.require &&
      !this.value?.filter((option) => option.selected).length
    ) {
      this.errors['value'] = 'Value is required';
    }
  }

  override getDto<EFormRadioElement>(): EFormRadioElement {
    return {
      type: this.type,
      name: this.name,
      value: this.value,
      showTitle: this.showTitle,
      require: this.require,
    } as EFormRadioElement;
  }
}

export class EFormDropdownElement extends EFormElementBase {
  type = EFormTypes.dropdown;

  name: string;
  value: EFormElementOption[];

  showTitle: boolean;
  require: boolean;
  placeholder: string;

  // no need for api
  label = 'Dropdown';
  icon = 'far fa-caret-square-down';

  constructor(element?: Partial<EFormDropdownElement>) {
    super();

    this.name = element?.name ?? '';
    this.value = element?.value ?? [
      {
        text: '',
        selected: true,
      },
    ];

    this.showTitle = element?.showTitle ?? true;
    this.require = element?.require ?? false;
    this.placeholder = element?.placeholder ?? '';
  }

  override validateForAdmin(): void {
    if (this.showTitle && !this.name?.trim().length) {
      this.errors['name'] = 'Title is required';
    }
    if (
      this.value &&
      !this.value.some((option) => option.text?.trim().length)
    ) {
      this.errors['value'] = 'Some option names are empty';
    }
    if (
      this.value &&
      this.value.map((option) => option.text).length >
        new Set(this.value.map((option) => option.text)).size
    ) {
      this.errors['value'] = 'Some option names are duplicated';
    }
  }

  override validateForUser(): void {
    if (
      this.require &&
      !this.value?.filter((option) => option.selected).length
    ) {
      this.errors['value'] = 'Value is required';
    }
  }

  override getDto<EFormDropdownElement>(): EFormDropdownElement {
    return {
      type: this.type,
      name: this.name,
      value: this.value,
      showTitle: this.showTitle,
      require: this.require,
      placeholder: this.placeholder,
    } as EFormDropdownElement;
  }
}

export class EFormTableElement extends EFormElementBase {
  type = EFormTypes.table;

  name: string;
  value: EFormTableRow[];

  showTitle: boolean;
  can_add_row: boolean;
  columns: EFormTableColumn[];

  // no need for api
  label = 'Table';
  icon = 'far fa-table';
  fullWidth = true;

  constructor(element?: Partial<EFormTableElement>) {
    super();

    this.name = element?.name ?? '';
    this.value = element?.value ?? [];

    this.showTitle = element?.showTitle ?? true;
    this.can_add_row = element?.can_add_row ?? false;
    this.columns = element?.columns ?? [
      new EFormTableColumn(),
      new EFormTableColumn(),
    ];
  }

  override validateForAdmin(): void {
    if (this.showTitle && !this.name?.trim().length) {
      this.errors['name'] = 'Title is required';
    }

    if (!(this.columns && this.columns.length)) {
      this.errors['value'] = 'Table has no columns';
      return;
    }

    const hasEmptyColumnName = this.columns.some(
      (column) => !column.name?.trim().length,
    );
    if (hasEmptyColumnName) {
      this.errors['value'] = 'Some column headers are empty';
      return;
    }

    const hasDuplicateChoices = this.columns.some(
      (column) =>
        column.choices.length > new Set(column.choices).size,
    );
    if (hasDuplicateChoices) {
      this.errors['value'] = 'Some columns have duplicate choices';
      return;
    }

    if (!this.can_add_row && !(this.value && this.value.length)) {
      this.errors['value'] = 'Table has no rows';
      return;
    }

    const hasInvalidEmail = this.value.some((row) =>
      Object.keys(row).some((key) => {
        const isEmailColumn = this.columns.some(
          (column) =>
            column.key === key &&
            column.columnType === EFormTableColumnTypes.email,
        );
        return (
          isEmailColumn &&
          new FormControl(row[key], [Validators.email]).invalid
        );
      }),
    );
    if (hasInvalidEmail) {
      this.errors['value'] = 'Table has an invalid email';
      return;
    }
  }

  protected override validateForUser(): void {
    const hasInvalidEmail = this.value?.some((row) =>
      Object.keys(row).some((key) => {
        const isEmailColumn = this.columns.some(
          (column) =>
            column.key === key &&
            column.columnType === EFormTableColumnTypes.email,
        );
        return (
          isEmailColumn &&
          new FormControl(row[key], [Validators.email]).invalid
        );
      }),
    );
    if (hasInvalidEmail) {
      this.errors['value'] = 'Table has an invalid email';
      return;
    }

    const hasEmptyRequired = this.value?.some((row) =>
      Object.keys(row).some((key) => {
        const isRequiredColumn = this.columns.some(
          (column) => column.key === key && column.require,
        );
        return (
          isRequiredColumn && !(row[key] as string)?.trim().length
        );
      }),
    );
    if (hasEmptyRequired) {
      this.errors['value'] = 'Table has an empty required field';
      return;
    }
  }

  override getDto<EFormTableElement>(): EFormTableElement {
    return {
      type: this.type,
      name: this.name,
      value: this.value,
      showTitle: this.showTitle,
      can_add_row: this.can_add_row,
      columns: this.columns,
    } as EFormTableElement;
  }
}

export type EFormElement =
  | EFormEmptyElement
  | EFormHeadingElement
  | EFormTextElement
  | EFormRichtextElement
  | EFormEmailElement
  | EFormDateElement
  | EFormPhoneElement
  | EFormCheckboxElement
  | EFormTableElement
  | EFormFileElements;

export type EFormFileElements = EFormImageElement;

export interface EFormElementOption {
  text: string;
  selected: boolean;
}

export interface EFormFileData {
  file_key?: string;
  file: string | File | null;
  type: 'file' | 'image';
}

export class EFormTableColumn {
  type = 'table_column';
  key: string;
  name: string;
  columnType: EFormTableColumnTypes;
  require: boolean;
  placeholder: string | null;
  choices: string[];

  constructor(eFormTableColumn?: Partial<EFormTableColumn>) {
    this.key = eFormTableColumn?.key ?? nanoid();
    this.name = eFormTableColumn?.name ?? '';
    this.columnType =
      eFormTableColumn?.columnType ?? EFormTableColumnTypes.text;
    this.require = eFormTableColumn?.require ?? false;
    this.placeholder = eFormTableColumn?.placeholder ?? null;
    this.choices = eFormTableColumn?.choices ?? [];
  }
}

export class EFormTableRow {
  [key: string]: string | string[] | null;
}

export interface EFormSnapData {
  ref_template?: number;
  snap_template?: EForm;
}

export interface EFormAttachment {
  id: number;
  file: string | null;
  file_key: string;
  file_name: string;
  is_image: boolean;
  eform_template: number;
}
