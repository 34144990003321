import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { memoPasswordRouteGuard } from '@core/guards/memo-password-route.guard';
import { AuthGuard } from '@core/guards/auth.guard';
import { HomeComponent } from '@core/components/home/home.component';
import { PageNotFoundComponent } from '@core/components/page-not-found/page-not-found.component';
import { AdfsCallbackComponent } from '@core/components/adfs-callback/adfs-callback.component';
import { MaintenanceComponent } from '@core/components/maintenance/maintenance.component';
import { PermissionNotFoundComponent } from '@shared/components/permission-not-found/permission-not-found.component';
import { AccountLockedComponent } from '@core/components/account-locked/account-locked.component';
import { DashboardContainerComponent } from '@core/components/dashboard-container/dashboard-container.component';

@Component({
  selector: 'app-media-window-component',
  template: 'file is in download folder',
  styleUrls: [],
  standalone: true,
})
class MediaWindowComponent {}

const routes: Routes = [
  {
    canActivate: [AuthGuard],
    data: { permission: ['General', 'Master Admin', 'Admin'] },
    path: '',
    component: HomeComponent,
  },
  {
    path: 'login',
    component: DashboardContainerComponent,
  },
  {
    path: 'forget-password',
    component: DashboardContainerComponent,
  },
  {
    path: 'adfs-callback',
    component: AdfsCallbackComponent,
  },
  {
    path: 'window-media',
    component: MediaWindowComponent,
  },
  {
    path: 'profile-detail',
    data: {
      permission: ['General', 'Master Admin', 'Admin', 'Auditor'],
    },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/profile/profile.module').then(
        (m) => m.ProfileModule,
      ),
  },
  {
    path: 'news-feed',
    data: { permission: ['General', 'Master Admin', 'Admin'] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/new-feed/new-feed.module').then(
        (m) => m.NewFeedModule,
      ),
  },
  {
    path: 'announcement',
    data: { permission: ['General', 'Master Admin', 'Admin'] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/announcement/announcement.module').then(
        (m) => m.AnnouncementModule,
      ),
  },
  {
    path: 'configuration',
    data: { permission: ['Master Admin', 'Admin'] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/setting/setting.module').then(
        (m) => m.SettingModule,
      ),
  },
  {
    path: 'users',
    data: { permission: ['Master Admin', 'Admin', 'Auditor'] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/users/users.module').then(
        (m) => m.UsersModule,
      ),
  },
  {
    path: 'department',
    data: { permission: ['Master Admin', 'Admin', 'Auditor'] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/department/department.module').then(
        (m) => m.DepartmentModule,
      ),
  },
  {
    path: 'manage-types-upload-memo',
    data: { permission: ['Master Admin', 'Admin'] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/manage-types-upload-memo/manage-types-upload-memo.module'
      ).then((m) => m.ManageTypesUploadMemoModule),
  },
  {
    path: 'e-form',
    data: { permission: ['Master Admin', 'Admin'] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/e-form/e-form.module').then(
        (m) => m.EFormModule,
      ),
  },
  {
    path: 'manage-csr',
    data: { permission: ['Master Admin', 'Admin'] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/manage-csr/manage-csr.module').then(
        (m) => m.ManageCsrModule,
      ),
  },
  {
    path: 'kpi-dashboard',
    data: { permission: ['Master Admin', 'Admin'] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
  },
  {
    path: 'usage-dashboard',
    data: { permission: ['Admin', 'Master Admin'] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/usage/usage.module').then(
        (m) => m.UsageModule,
      ),
  },
  {
    path: 'memo-report',
    data: { permission: ['Admin', 'Master Admin'] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/memo-report/memo-report.module').then(
        (m) => m.MemoReportModule,
      ),
  },
  {
    path: 'operation-log',
    data: { permission: ['Admin', 'Master Admin', 'Auditor'] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/operation-log/operation-log.module'
      ).then((m) => m.OperationLogModule),
  },
  {
    path: 'other-settings',
    data: { permission: ['Admin', 'Master Admin'] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/other-setting/other-settings.module'
      ).then((m) => m.OtherSettingsModule),
  },
  {
    path: 'pending-approval',
    data: { permission: ['General', 'Admin'] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modules/approval-request/approval-request.module'
      ).then((m) => m.ApprovalRequestModule),
  },
  {
    path: 'approval',
    loadChildren: () =>
      import('../app/modules/approval/approval.module').then(
        (m) => m.ApprovalModule,
      ),
  },
  {
    path: 'loa',
    data: { permission: ['Master Admin', 'Admin'] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/loa/loa.module').then(
        (m) => m.LoaModule,
      ),
  },
  {
    path: 'cc-group',
    data: { permission: ['Master Admin', 'Admin'] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/cc-group/cc-group.module').then(
        (m) => m.CcGroupModule,
      ),
  },
  {
    path: 'memos',
    loadChildren: () =>
      import('./modules/memos/memo.module').then((m) => m.MemoModule),
  },
  {
    path: 'certificate',
    data: { permission: ['Master Admin', 'Admin', 'Auditor'] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/certificate/certificate.module').then(
        (m) => m.CertificateModule,
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import(
        '../app/modules/reset-password/reset-password.module'
      ).then((m) => m.ResetPasswordModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('../app/modules/register/register.module').then(
        (m) => m.RegisterModule,
      ),
  },
  {
    path: 'set-memo-password',
    loadChildren: () =>
      import(
        '../app/modules/reset-password-via-link/reset-password-via-link.module'
      ).then((m) => m.ResetPasswordViaLinkModule),
  },
  {
    path: 'levels-hierarchy',
    data: { permission: ['Master Admin', 'Admin', 'Auditor'] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/level-of-hierarchy/level-of-hierarchy.module'
      ).then((m) => m.LevelOfHierarchyModule),
  },
  { path: 'website-maintenance', component: MaintenanceComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'account-locked', component: AccountLockedComponent },
  {
    path: 'permission-not-found',
    component: PermissionNotFoundComponent,
  },
  {
    path: 'folders',
    loadChildren: () =>
      import('./modules/folder/folder.module').then(
        (m) => m.FolderModule,
      ),
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
