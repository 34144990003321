import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'loaNameThai',
})
export class LoaNameThPipe implements PipeTransform {
  transform(value?: string, disable = false): string | undefined {
    if (disable) {
      return value;
    }
    return value
      ?.replace('level', 'ลำดับที่')
      ?.replace('Level', 'ลำดับที่');
  }
}
