<!-- OTP by google authentication -->
<ng-template #otpGoogleAuthentication
    let-modal>
  <app-modal-confirmation-std4-skin [header]="'APPROVAL.Authenticator OTP Verification' | translate"
      [dismissText]="'CREATE-USER.CANCEL' | translate"
      [submitText]="'CREATE-USER.CONFIRM'|translate"
      [isLoading]="isSaved"
      [bodyNotDesignDownload]="false"
      [isSubmitDisabled]="invalidOTP"
      (dismiss)="close()"
      (submitted)="otpChange.emit(otp)">
    <ng-template>
      <div class="form-group mt-0">
        <div class="d-flex justify-content-center mb-3 mt-0">
          <svg height="120"
              viewBox="0 0 400 130"
              width="250">
            <use href="assets/images/pictograms/paint.svg#paint"
                height="125"
                fill="#E8EEFB"
                width="375"
                y="8">
            </use>
            <use href="assets/images/pictograms/googleAuthentication.svg#googleAuthentication"
                height="180"
                width="200"
                x="95"
                y="-30">
            </use>
          </svg>
        </div>
        <div class="text-center mt-3 mb-3 custom-box">
          <div class="mb-3 fs-base-l0 fw-medium">
            {{"PROFILE.Enter the OTP from your authentication app"|translate}}
          </div>
          <ng-otp-input #ngOtpInput
              (onInputChange)="onOtpChange($event)"
              [config]="config"
              (keydown.enter)="otpChange.emit(otp)">
          </ng-otp-input>
        </div>
      </div>
    </ng-template>
  </app-modal-confirmation-std4-skin>
</ng-template>

<!-- OTP by email -->
<ng-template #otpEmail
    let-modal>
  <app-modal-confirmation-std4-skin [header]="'APPROVAL.Email OTP Verification' | translate"
      [dismissText]="'CREATE-USER.CANCEL' | translate"
      [submitText]="'CREATE-USER.CONFIRM'|translate"
      [isLoading]="isSaved || isLoadingOTP"
      [bodyNotDesignDownload]="false"
      [isSubmitDisabled]="invalidOTP"
      (dismiss)="close()"
      (submitted)="otpChange.emit(otp)">
    <ng-template>
      <div class="form-group mt-0">
        <div class="d-flex justify-content-center mb-3 mt-0">
          <svg height="120"
              viewBox="0 0 400 130"
              width="250">
            <use href="assets/images/pictograms/paint.svg#paint"
                height="125"
                fill="#E8EEFB"
                width="375"
                y="8">
            </use>
            <use href="assets/images/pictograms/mail-shield-lock.svg#mail-shield-lock"
                height="150"
                width="200"
                x="95"
                y="-14">
            </use>
          </svg>
        </div>
        <div class="text-center mb-4">
          <div class="mb-1 fs-base-l0 fw-medium">
            {{"Email-OTP.EMAIL-OTP-SENT" | translate}} {{ hiddenEmail }} (Ref: {{refDisPlay}})
          </div>
          <div class="text-gray-neutrals fs-base-l4 countdown"
              [class.text-danger]="timeLeft === 0">
            {{'SMS-OTP.Time remaining' | translate}}
            {{ timeLeft * 1000 | date:'mm:ss' }} {{"SMS-OTP.minutes" | translate}}
          </div>
        </div>
        <div class="text-center mt-3 mb-3 custom-box">
          <ng-otp-input #ngOtpInput
              (onInputChange)="onOtpChange($event)"
              [config]="config"
              (keydown.enter)="otpChange.emit(otp)">
          </ng-otp-input>
        </div>
        <div class="text-center mt-4">
          <span>
            <a class="text-blue pointer"
                (click)="resend()">{{"SMS-OTP.Resend OTP" | translate}}</a>
          </span>
        </div>
      </div>
    </ng-template>
  </app-modal-confirmation-std4-skin>
</ng-template>

<!-- OTP by phone number -->
<ng-template #otpPhoneNumber
    let-modal>
  <app-modal-confirmation-std4-skin [header]="'APPROVAL.OTP Verification' | translate"
      [dismissText]="'CREATE-USER.CANCEL' | translate"
      [submitText]="'CREATE-USER.CONFIRM'|translate"
      [bodyNotDesignDownload]="false"
      [isLoading]="isSaved || isLoadingOTP"
      [isSubmitDisabled]="invalidOTP"
      (dismiss)="close()"
      (submitted)="otpChange.emit(otp)">
    <ng-template>
      <div class="form-group mt-0">
        <div class="d-flex justify-content-center mb-3 mt-0">
          <svg height="120"
              viewBox="0 0 400 130"
              width="400">
            <use href="assets/images/pictograms/paint.svg#paint"
                height="125"
                fill="#E8EEFB"
                width="375"
                y="8">
            </use>
            <use href="assets/images/pictograms/shield-phone-password.svg#shield-phone-password"
                height="150"
                width="200"
                x="95"
                y="-14">
            </use>
          </svg>
        </div>

        <div class="text-center mb-4">
          <div class="mb-1 fs-base-l0 fw-medium">
            {{"CREATE-USER.OTP-SENT" | translate}} {{ hiddenNumber }} (Ref: {{refDisPlay}})
          </div>
          <div class="text-gray-neutrals fs-base-l4 countdown"
              [class.text-danger]="timeLeft === 0">
            {{'SMS-OTP.Time remaining' | translate}}
            {{ timeLeft * 1000 | date:'mm:ss' }} {{"SMS-OTP.minutes" | translate}}
          </div>
        </div>
        <div class="text-center mt-3 mb-3 custom-box">
          <ng-otp-input #ngOtpInput
              (onInputChange)="onOtpChange($event)"
              [config]="config"
              (keydown.enter)="otpChange.emit(otp)">
          </ng-otp-input>
        </div>
        <div class="text-center mt-4">
          <span>
            <a class="text-blue pointer"
                (click)="resend()">{{"SMS-OTP.Resend OTP" | translate}}</a>
          </span>
        </div>
      </div>
    </ng-template>
  </app-modal-confirmation-std4-skin>
</ng-template>