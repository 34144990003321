import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import * as _ from 'lodash';

export function validateInputNumber(
  control: AbstractControl,
): { [key: string]: any } | null {
  if (control.value && control.value < 0) {
    control.setValue(0);
  } else if (!control.value && control.value !== 0) {
    return { required: true };
  }
  return null;
}

export function idNumberValidator(
  control: AbstractControl,
): { [key: string]: any } | any {
  if (control.value != null) {
    const id = control.value.toString().replace(/-/gi, '');
    if (id.length !== 13) {
      return {
        invalidNumber: { valid: false, value: control.value },
      };
    }
    const container: any = [];
    let sum: number | any = null;
    let result = null;
    let digitCount = 13;
    _.each(id, (char) => {
      container.push(char);
    });
    const lastDigit = container.pop();
    _.each(container, (i) => {
      sum += digitCount-- * i;
    });
    sum = sum % 11;

    if (sum <= 1) {
      result = 1 - sum;
    } else if (sum > 1) {
      result = 11 - sum;
    }
    // tslint:disable-next-line:radix
    return result === parseInt(lastDigit)
      ? null
      : { invalidNumber: { valid: false, value: control.value } };
  }
}

export function forbiddenNameValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // eslint-disable-next-line no-useless-escape
    const regexp = new RegExp(/[`<">'/;&*()%!+{}\[\]\|]/);
    const forbidden = regexp.test(control.value);
    return forbidden
      ? { forbiddenName: { value: control.value } }
      : null;
  };
}

export function passwordValidator(
  passwordControl: AbstractControl,
): ValidatorFn {
  return (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    let errors: ValidationErrors | null =
      passwordControl?.errors || {};
    if (!control.value) {
      errors['valid'] = false;
      passwordControl?.setErrors(errors);
      return { valid: false };
    } else {
      delete errors['valid'];
      if (Object.keys(errors).length === 0) {
        errors = null;
      }
      passwordControl?.setErrors(errors);
    }
    return null;
  };
}

export function passwordMatchValidator(
  passwordControl: AbstractControl,
): ValidatorFn {
  return (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    return control.value === passwordControl.value
      ? null
      : {
          confirm_matched: false,
        };
  };
}
