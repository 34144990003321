import {
  computed,
  effect,
  inject,
  Injectable,
  Renderer2,
  RendererFactory2,
  signal,
  Signal,
  WritableSignal,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Primitive } from '@shared/models/common.model';
import {
  BehaviorSubject,
  catchError,
  forkJoin,
  map,
  Observable,
  take,
  tap,
} from 'rxjs';
import { MemoService } from 'src/app/modules/memos/service/memo.service';
import { ErrorNotification } from 'src/app/store/memo/memo.actions';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  badgeSource: { [k: string]: Observable<Primitive> };
  containerScrollingFinishedSubject = new BehaviorSubject<boolean>(
    false,
  );
  containerScrollingFinished =
    this.containerScrollingFinishedSubject.asObservable();

  private badgeStore: WritableSignal<{ [k: string]: Primitive }> =
    signal({});
  private memoService = inject(MemoService);
  private navigationElement: WritableSignal<NavigationElement> =
    signal({});
  private renderer: Renderer2;
  private rendererFactory = inject(RendererFactory2);
  private store = inject(Store);

  constructor() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.badgeSource = {
      // TODO: add myTask to this
      // * Push other badge source to this
    };
    // this.fetchBadge();
    effect(() => {
      this.initializeCssVar();
    });
  }

  fetchBadge(
    specify?: string[],
  ): Observable<{ [k: string]: Primitive }> {
    const source = specify
      ? specify.reduce((obj, key) => {
          if (this.badgeSource[key]) {
            obj[key] = this.badgeSource[key];
          }
          return obj;
        }, {} as { [k: string]: Observable<Primitive> })
      : this.badgeSource;
    const obs = forkJoin(source).pipe(
      tap((res) => {
        return this.badgeStore.update((badge) => ({
          ...badge,
          ...res,
        }));
      }),
      take(1),
      catchError((err) => {
        this.store.dispatch(new ErrorNotification(err));
        throw err;
      }),
    );
    obs.subscribe();
    return obs;
  }

  initializeCssVar(): void {
    const navigationElement = this.navigationElement();
    let cssText = '';
    Object.keys(navigationElement).forEach((key) => {
      const element =
        navigationElement[key as keyof NavigationElement];
      const prefix = `--${key.toLowerCase()}`;
      cssText += `${prefix}-height: ${element?.clientHeight}px;`;
      cssText += `${prefix}-width: ${element?.clientWidth}px;`;
    });
    const styleElId = 'sidebar-variables-styles';
    let styleEl = document.getElementById(styleElId);
    if (!styleEl) {
      styleEl = this.renderer.createElement(
        'style',
      ) as HTMLStyleElement;
      styleEl.setAttribute('id', styleElId);
      const headEl = document.getElementsByTagName('head');
      this.renderer.appendChild(headEl.item(0), styleEl);
    }
    styleEl.innerHTML = `:root{${cssText}}`;
  }

  getBadge(): Signal<{ [k: string]: Primitive }> {
    return this.badgeStore;
  }

  getNavigationElement(): Signal<NavigationElement>;
  getNavigationElement(
    elementType: keyof NavigationElement,
  ): Signal<HTMLDivElement | undefined>;
  getNavigationElement(elementType?: keyof NavigationElement) {
    if (elementType == null) {
      return this.navigationElement.asReadonly();
    }
    return computed(() => this.navigationElement()[elementType]);
  }

  setContainerScrollingFinished(event: boolean) {
    this.containerScrollingFinishedSubject.next(event);
  }

  setNavigationElement(
    type: keyof NavigationElement,
    element: HTMLDivElement | undefined,
  ): void {
    this.navigationElement.update((el) => ({
      ...el,
      [type]: element,
    }));
  }
}

export interface NavigationElement {
  navbar?: HTMLDivElement;
  pageContainer?: HTMLDivElement;
  sidebar?: HTMLDivElement;
}
