<div class="dept-dd-container row">
    <div class="text-black-neutrals fw-medium mb-1">
        {{'LOA.CHOOSE-DEPARTMENT' | translate}}
    </div>
    <div class="dept-dd-dropdown col-md-6"
        *ngFor="let dropdown of dropdownList; let i = index">
        <ng-select *ngIf="!refreshDropdown"
            class="w-100 m-t-sm-3"
            [items]="dropdown"
            bindValue="id"
            [ngClass]="i > 1 ? 'custom-mt': ''"
            [notFoundText]="'SELECT.No items found'|translate"
            bindLabel="{{translated === 'th' ? 'department_name' : 'department_name_en'}}"
            placeholder="{{'MEMOS.SELECT' | translate}}"
            [(ngModel)]="selectedDepartmentID[i] ? selectedDepartmentID[i]  : currentDepartmentId"
            (change)="selectedDepartment(i, $event)">
        </ng-select>
        <div *ngIf="i < dropdownList.length - 1"
            class="dept-dd-separator">/</div>
    </div>

    <div class="col-md-6 text-end-sm m-t-sm-3 d-flex"
        [ngClass]="dropdownList.length > 1 ? 'mt-3':''">
        <!-- TODO: Need checking `dropdownList.l` -->
        <button *ngIf="showNextLevelButton"
            class="btn btn-std btn-std-2ry fs-base-l0 mx-1 fw-semibold btn-w-80 justify-content-center"
            (click)="showNextLevelButtonClicked()">
            {{$any(dropdownList).l}}
            {{'DEPARTMENT.SELECT-SUB-DEPARTMENT' | translate}}
        </button>
        <button class="btn btn-std btn-std-2ry fs-base-l0 mx-1 fw-semibold btn-w-80 justify-content-center"
            (click)="clearData()">
            {{clearLabel | translate}}
        </button>
    </div>
</div>