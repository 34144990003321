import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { COUNTRY_CODES } from '@shared/constants/countries.constant';
import { CountryCode } from '@shared/models/common.model';
import { debounceTime, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
})
export class InputPhoneComponent implements OnChanges, OnDestroy {
  @Input() value = '';
  @Input() invalid = false;
  @Input() borderVisible = true;
  @Input() placeholder?: string;

  @Output() valueChange = new EventEmitter<string>();

  defaultMaskConfig = { mask: /^[+\-()\d]+$/i };

  search = '';
  countryCodes: CountryCode[] = COUNTRY_CODES;
  selectedCountryCode: CountryCode = this.countryCodes[0];

  phoneNumber = '';
  phoneDeBouncer: Subject<string> = new Subject<string>();

  subscriptions = new Subscription();

  constructor() {
    this.subscribeDeBouncer();
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes['value'] &&
      this.handleValue(changes['value'].currentValue);
  }

  subscribeDeBouncer(): void {
    const subscription = this.phoneDeBouncer
      .pipe(debounceTime(600))
      .subscribe((number) => {
        this.onPhoneNumberChange(number);
      });
    this.subscriptions.add(subscription);
  }

  handleValue(value: string): void {
    if (!(value && value.trim().length)) return;

    const [code, number] = value.split(' ');

    this.selectedCountryCode =
      this.countryCodes.find((cc) => cc.code === code) ??
      this.countryCodes[0];
    this.phoneNumber = number ?? '';
  }

  onSearchCountry(value: string): void {
    value = value.trim();

    if (!value) return;

    this.countryCodes = COUNTRY_CODES.filter((cc) =>
      cc.name.toLowerCase().includes(value.toLowerCase()),
    );
  }

  onCountryCodeChange(countryCode: CountryCode) {
    this.emit(countryCode.code, this.phoneNumber);
  }

  onPhoneNumberChange(number: string): void {
    const matchingCC = this.countryCodes.find((cc) =>
      number.startsWith(cc.code),
    );

    if (matchingCC) {
      this.selectedCountryCode = matchingCC;
      this.phoneNumber = number = number.slice(
        matchingCC.code.length,
      );
    }

    this.emit(this.selectedCountryCode.code, number);
  }

  emit(code: string, number: string): void {
    const fullNumber = number.length ? code + ' ' + number : '';
    this.valueChange.emit(fullNumber);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
