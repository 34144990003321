import {
  Component,
  EventEmitter,
  Host,
  inject,
  Input,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  NgbDate,
  NgbDateAdapter,
  NgbDateStruct,
  NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { UtilsService } from 'src/app/core/services/utils.service';

export interface PickedDateRange {
  from: string;
  to: string;
}

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnChanges {
  @Input() clearable = true;
  @Input() calendarSvgIconPath?: string;
  @Input() invalid = false;
  @Input() placeholder: string;
  @Input() fromDate: NgbDateStruct | any;
  @Input() inputStyleClass?: string;
  @Input() toDate: NgbDateStruct | any;
  @Input() minDate: NgbDateStruct | any;
  @Input() maxDate: NgbDateStruct | any;
  @Output() fromDateChange = new EventEmitter<
    NgbDateStruct | any | null
  >();
  @Output() toDateChange = new EventEmitter<NgbDateStruct | any>();

  @Output() selected = new EventEmitter<PickedDateRange>();

  @Input() isClearDate: boolean;
  @Output() isClearDateChange = new EventEmitter();

  @ViewChild('datepicker') datepicker: NgbInputDatepicker;

  calendarSvgPath?: string;
  formattedFromDate: string;
  formattedToDate: string;
  hoveredDate: NgbDateStruct | any;
  startDate: NgbDateStruct | null = null;
  endDate: NgbDateStruct | null = null;

  private isUsedEndDateAdapter = false;
  private isUsedStartDateAdapter = false;
  private utils = inject(UtilsService);

  constructor(
    @Optional()
    private adapter: NgbDateAdapter<any> | null,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fromDate']) {
      const date = changes['fromDate'].currentValue;
      if (!this.isNgbDateStruct(date)) {
        this.startDate = this.adapter
          ? this.adapter.fromModel(changes['fromDate'].currentValue)
          : changes['fromDate'].currentValue;
        this.isUsedStartDateAdapter = !!this.adapter;
      } else {
        this.startDate = date;
        this.isUsedStartDateAdapter = false;
      }
    }
    if (changes['toDate']) {
      const date = changes['toDate'].currentValue;
      if (!this.isNgbDateStruct(date)) {
        this.endDate = this.adapter
          ? this.adapter.fromModel(changes['toDate'].currentValue)
          : changes['toDate'].currentValue;
        this.isUsedEndDateAdapter = !!this.adapter;
      } else {
        this.endDate = date;
        this.isUsedEndDateAdapter = false;
      }
    }
    setTimeout(() => {
      if (changes['isClearDate']) {
        this.clearDate();
      }
    }, 0);
    if (changes['calendarSvgIconPath']) {
      const path = changes['calendarSvgIconPath'].currentValue;
      this.calendarSvgPath =
        path == null ? path : this.utils.appendTagFromSvgPath(path);
    }
  }

  onClickOutsideDatepicker(): void {
    this.emitSelectedDates();
  }

  emitSelectedDates(): void {
    const selectedDates = {
      from: this.formattedFromDate,
      to: this.formattedToDate ?? this.formattedFromDate,
    };
    this.selected.emit(selectedDates);
  }

  emitEndDate() {
    this.isUsedEndDateAdapter
      ? this.toDateChange.emit(
          this.adapter
            ? this.adapter?.toModel(this.endDate)
            : this.startDate,
        )
      : this.toDateChange.emit(this.endDate);
  }

  emitStartDate() {
    this.isUsedStartDateAdapter
      ? this.fromDateChange.emit(
          this.adapter
            ? this.adapter?.toModel(this.startDate)
            : this.startDate,
        )
      : this.fromDateChange.emit(this.startDate);
  }

  onDateSelection(date: NgbDate): void {
    if (!this.startDate && !this.endDate) {
      this.startDate = date;
      this.emitStartDate();
      this.formattedFromDate = this.setFormatDate(
        this.startDate,
        '-',
      );
    } else if (
      this.startDate &&
      !this.endDate &&
      date.equals(this.startDate)
    ) {
      this.endDate = this.startDate;
      this.emitEndDate();
      this.formattedToDate = this.setFormatDate(this.endDate, '-');
      this.onSelected();
    } else if (
      this.startDate &&
      !this.endDate &&
      date &&
      date.after(this.startDate)
    ) {
      this.endDate = date;
      this.emitEndDate();
      this.formattedToDate = this.setFormatDate(this.endDate, '-');
      this.onSelected();
    } else {
      this.endDate = null;
      this.emitEndDate();
      this.startDate = date;
      this.emitStartDate();
      this.formattedFromDate = this.setFormatDate(
        this.startDate,
        '-',
      );
    }
  }

  compareFromDate(date: NgbDate) {
    const fromDate = NgbDate.from(this.isSelectedFromDate);
    return date.equals(fromDate);
  }

  compareToDate(date: NgbDate) {
    const toDate = NgbDate.from(this.isSelectedToDate);
    return date.equals(toDate);
  }

  formDateEqualsToDate(date: NgbDate) {
    return this.compareFromDate(date) && this.compareToDate(date);
  }

  onSelected(): void {
    this.datepicker?.close();
    this.selected.emit({
      from: this.formattedFromDate,
      to: this.formattedToDate,
    });
  }

  setFormatDate(
    date: NgbDateStruct,
    str: string,
    reverse = false,
  ): string {
    return reverse
      ? date.day + str + date.month + str + date.year
      : date.year + str + date.month + str + date.day;
  }

  isHovered(date: NgbDate): boolean {
    return (
      this.startDate &&
      !this.endDate &&
      this.hoveredDate &&
      date.after(this.startDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate): boolean {
    return (
      this.endDate != null &&
      date.after(this.startDate) &&
      date.before(this.endDate)
    );
  }

  isNgbDateStruct(date: any): date is NgbDateStruct {
    return date && date.year && date.month && date.day;
  }

  isRange(date: NgbDate): boolean {
    return (
      date.equals(this.startDate) ||
      (this.endDate && date.equals(this.endDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  clearDate() {
    this.formattedFromDate = '';
    this.formattedToDate = '';
    this.startDate = null;
    this.endDate = null;
    this.hoveredDate = null;
    this.onSelected();
    this.isClearDateChange.emit();
  }

  get rangeDate(): string {
    if (!this.startDate) {
      return '';
    }

    if (!this.endDate) {
      return this.setFormatDate(this.startDate, '/', true);
    }

    return (
      this.setFormatDate(this.startDate, '/', true) +
      '  -  ' +
      this.setFormatDate(this.endDate, '/', true)
    );
  }

  get isSelectedFromDate(): NgbDateStruct | null {
    if (
      this.formattedFromDate &&
      typeof this.formattedFromDate === 'string'
    ) {
      const split = this.formattedFromDate.split('-');
      return {
        year: Number(split[0]),
        month: Number(split[1]),
        day: Number(split[2]),
      };
    }
    return null;
  }

  get isSelectedToDate(): NgbDateStruct | null {
    if (
      this.formattedToDate &&
      typeof this.formattedToDate === 'string'
    ) {
      const split = this.formattedToDate.split('-');
      return {
        year: Number(split[0]),
        month: Number(split[1]),
        day: Number(split[2]),
      };
    }
    return null;
  }
}
