import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Signal,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import {
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { DisplayService } from '@core/services/display.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { DeviceType, Primitive } from '@shared/models/common.model';
import * as $ from 'jquery';
import { NotificationService } from 'src/app/shared/service/notification.service';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';
import { NavbarService } from '../navbar/navbar.service';
import { TitleService } from '@shared/service/title-service';
import { ProfileService } from 'src/app/modules/profile/shared/profile.service';
import { PermissionService } from '../../services/permission.service';
import { featureFlag } from 'src/environments/environment';
import { Subscription, combineLatest } from 'rxjs';
import { MemoPreviewState } from 'src/app/store/memo-preview/memo-preview.state';
import { CloseMemoPreview } from 'src/app/store/memo-preview/memo-preview.actions';
import { SidebarService } from './sidebar.service';
import { NavbarComponent } from '../navbar/navbar.component';
import { Person } from '@shared/models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogOutModalComponent } from '../log-out-modal/log-out-modal.component';
import { ClearMemoPasswordPopup } from 'src/app/store/memo/memo.actions';
import { ApprovalRequestState } from 'src/app/store/approval-request/approval-request.state';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @ViewChild(NavbarComponent, { read: ElementRef<HTMLElement> })
  set navbarElRef(element: ElementRef | undefined) {
    this.sidebarService.setNavigationElement(
      'navbar',
      element?.nativeElement,
    );
  }
  @ViewChild('pageContainer') set pageContainerElRef(
    element: ElementRef<HTMLDivElement> | undefined,
  ) {
    this.sidebarService.setNavigationElement(
      'pageContainer',
      element?.nativeElement,
    );
  }
  @ViewChild('pageView', { static: false })
  pageViewElRef: ElementRef;
  @ViewChild('sidebar', { static: false }) set sidebarElRef(
    element: ElementRef<HTMLDivElement> | undefined,
  ) {
    this.sidebarService.setNavigationElement(
      'sidebar',
      element?.nativeElement,
    );
  }

  activeSidebarColor?: string;
  primaryTextColor?: string;
  badge: Signal<{ [k: string]: Primitive }> = signal({});
  deviceType: DeviceType = 'desktop';
  navbarActive = false;
  flags: any;
  profile: Person;
  showWarning = false;
  getWarningEnableOTPFirstTime = true;
  earlier: string;
  translateSidebar: any;
  page = 1;
  isLoading = false;

  themeList?: ThemeList;

  language: string;
  isFullSidebar = true;
  managementSubMenu = true;
  openSidebar = false;
  manageCertificate = true;
  selectedLanguage: string | any;
  isPreview: boolean;
  subscription: Subscription[] = [];
  getWidthById: number;

  private displayService = inject(DisplayService);
  private sidebarService = inject(SidebarService);
  private modalService = inject(NgbModal);

  constructor(
    public notificationService: NotificationService,
    private router: Router,
    private translate: TranslateService,
    private themeService: ThemeService,
    private navbar: NavbarService,
    private profileService: ProfileService,
    private permission: PermissionService,
    private store: Store,
    private titleService: TitleService,
  ) {
    this.subscription.push(
      router.events.subscribe((val) => {
        if (val instanceof NavigationStart) {
          this.store.dispatch(new CloseMemoPreview());
          this.store.dispatch(new ClearMemoPasswordPopup());
          this.modalService.dismissAll();
        }
        if (val instanceof NavigationEnd) {
          this.openSidebar = false;
        }
      }),
    );
    this.subscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
        this.activeSidebarColor =
          this.themeService.getHslColorValueByName('std-color-3');
        this.primaryTextColor =
          this.themeService.getHslColorValueByName('std-color-5');
      }),
    );
    this.subscription.push(
      this.titleService.getSidebar().subscribe((res) => {
        this.openSidebar = !this.openSidebar;
        this.isFullSidebar = res;
      }),
    );
    this.subscription.push(
      this.store.subscribe((state) => {
        this.profile = state.auth.user_info;
        if (
          featureFlag.ddoc &&
          this.getWarningEnableOTPFirstTime &&
          this.profile &&
          this.permission.checkAccess(['General'])
        ) {
          this.getWarningEnableOTPFirstTime = false;
          const subscription = this.profileService
            .getWarningEnableOTP()
            .subscribe((data: any) => {
              this.showWarning = data.show_warning;
            });
          this.subscription.push(subscription);
        }
      }),
    );
    this.store.select(MemoPreviewState).subscribe({
      next: (res) => {
        this.isPreview = res.isPreview;
      },
    });
  }

  ngOnInit(): void {
    $('#menu-toggle').click((e: any) => {
      e.preventDefault();
      $('#wrapper').toggleClass('toggled');
    });
    this.flags = [
      { label: 'English', value: 'en' },
      { label: 'Thai', value: 'th' },
    ];
    this.selectedLanguage = localStorage.getItem('lang');
    this.translateSidebar = this.translate;
    this.navbar.data.subscribe((res) => {
      setTimeout(() => {
        this.navbarActive = res;
      });
    });
    this.notificationService.updateNotificationCount();
    this.checkRouteForSubmenu();
    this.observeDisplay();
    this.sidebarService.fetchBadge();
    this.badge = this.sidebarService.getBadge();
  }

  ngOnDestroy(): void {
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    });
  }

  checkRouteForSubmenu(): void {
    const url = this.router.url.split('/')[1];
    const manageOrg = [
      { url: 'department' },
      { url: 'users' },
      { url: 'loa' },
      { url: 'cc-group' },
      { url: 'memos/trash' },
    ];
    const certificate = [
      { url: 'manage-csr' },
      { url: 'certificate' },
    ];
    const checkPathOrg = manageOrg.some((res) => res.url === url);
    const checkPathCertificate = certificate.some(
      (res) => res.url === url,
    );
    if (checkPathOrg) {
      this.managementSubMenu = true;
    }
    if (checkPathCertificate) {
      this.manageCertificate = true;
    }
  }

  checkRouteActive(url: string): boolean {
    return url.split('/')[1] === this.router.url.split('/')[1];
  }

  getSizeSidebar() {
    const sidebarWrapperElement = document.getElementById(
      'sidebar-wrapper',
    ) as HTMLElement;
    String(sidebarWrapperElement?.clientWidth);
    const sidebarElement = document.getElementById('sidebar-wrapper');
    if (sidebarElement) {
      const width = sidebarElement.clientWidth; // Get the client width of the element
      this.getWidthById = width; // Convert width to a string with 'px' unit
    }
  }

  navigateTo(): void {
    this.router.navigate(['/department', 'level']);
  }

  onResize(_$event: UIEvent) {
    this.openSidebar = false;
    this.sidebarService.initializeCssVar();
  }

  isUserActive() {
    const currentRoute = this.router.url
      .split('/')[1]
      .substring(0, 5);
    if (currentRoute === 'users') {
      return 'content-user-height';
    } else {
      return 'content-height';
    }
  }

  logOut() {
    this.modalService.open(LogOutModalComponent, {
      centered: true,
      backdrop: 'static',
      windowClass: 'background-blur-logout',
    });
  }

  navigateToProfile(): void {
    this.router.navigate(['/', 'profile-detail', 'detail']);
  }

  changeLanguage(lang: string): void {
    this.selectedLanguage = lang;
    if (lang === 'en') {
      this.translate.use('en');
      localStorage.setItem('lang', 'en');
    } else if (lang === 'th') {
      this.translate.use('th');
      localStorage.setItem('lang', 'th');
    }
  }

  containerScrollingFinished(): void {
    this.sidebarService.setContainerScrollingFinished(true);
  }

  observeDisplay(): void {
    this.displayService.getBreakpointDeviceObserver().subscribe({
      next: (res) => {
        this.deviceType = res;
        // Default opening sidebar
        this.openSidebar = false;
        if (this.deviceType === 'tablet') {
          this.isFullSidebar = false;
        } else {
          this.isFullSidebar = true;
        }
      },
    });
  }

  toggleSidebar(): void {
    if (this.deviceType === 'tablet') {
      this.isFullSidebar = !this.isFullSidebar;
      return;
    }
    if (this.themeService.isMobile()) {
      this.isFullSidebar = true;
      this.openSidebar = !this.openSidebar;
      return;
    }
    this.isFullSidebar = !this.isFullSidebar;
    setTimeout(() => {
      this.getSizeSidebar();
    }, 200);
  }

  closeSidebar(event: Event) {
    if (event) {
      const target = event.target as HTMLElement;
      const classList = target.classList;
      if (
        classList.value === '' ||
        target.tagName === 'BODY' ||
        target.id === 'sidebar-user'
      ) {
        return;
      }
      this.openSidebar = false;
      if (this.deviceType === 'tablet') {
        this.isFullSidebar = false;
      }
    }
  }

  get isTrashModule(): boolean {
    return this.router.url.includes('/memos/trash');
  }
}
