import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { DisplayService } from '@core/services/display.service';
import { DeviceType } from '@shared/models/common.model';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileDisplayComponent } from '../profile-display/profile-display.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { InputTypeNumberComponent } from '../input-type-number/input-type-number.component';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    TranslateModule,
    ProfileDisplayComponent,
    NgbModule,
    AngularSvgIconModule,
    InputTypeNumberComponent,
  ],
})
export class PaginationComponent implements OnDestroy {
  @ViewChild('maxLineInput') maxLineInput: ElementRef;

  @Input() boundaryLinks: boolean;
  @Input() count: number;
  @Input() ellipses: boolean;
  @Input() rotate: boolean;
  @Input() pageSize: number;
  @Input() newDesign = false;
  @Input() fontSm = false;
  @Input() maxSize = 5;
  @Output() pageSizeChange = new EventEmitter();

  @Input() page: number;
  @Output() pageChange = new EventEmitter();
  @Input() noneSize: boolean;
  @Input() pageTitle: string;

  isMobile: boolean;
  themeList?: ThemeList;
  paginationDropdown = ['10', '20', '30', '40', '50'];
  subscriptions: Subscription[] = [];
  debouncer$ = new Subject<string>();
  deviceType: DeviceType = 'desktop';

  private displayService = inject(DisplayService);
  private themeService = inject(ThemeService);

  private observeDisplay(): void {
    const subs = this.displayService
      .getBreakpointDeviceObserver()
      .subscribe({
        next: (res) => {
          this.deviceType = res;
        },
      });
    this.subscriptions.push(subs);
  }

  constructor() {
    this.debouncer$
      .pipe(debounceTime(10))
      .subscribe((pageCount: string) => {
        this.page = Number(pageCount);
        this.changePageEmit();
      });
    this.isMobile = this.themeService.isMobile();
    this.observeDisplay();
    this.subscriptions.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    });
  }

  changePageSizeEmit(e: any) {
    this.page = 1;
    this.pageSize = e;
    this.pageSizeChange.emit(this.pageSize);
  }

  changePageEmit() {
    this.pageChange.emit(this.page);
  }

  isShowInputNumber() {
    return ![
      'userPage',
      'approvalRequestPage',
      'newFeedPage',
    ].includes(this.pageTitle);
  }

  disabledOnWheel(event: WheelEvent) {
    event.preventDefault();
    this.maxLineInput?.nativeElement.blur();
  }

  getMaxSize(): number {
    return this.deviceType === 'mobile' ? 1 : this.maxSize;
  }

  get countPage() {
    return Math.ceil(this.count / this.pageSize);
  }
}
