import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardContainerComponent } from '@core/components/dashboard-container/dashboard-container.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

export const resetPasswordRoutes: Routes = [
  {
    path: ':id',
    component: DashboardContainerComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'listofapproval',
      },
      {
        path: ':menu',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'listofapproval',
          },
          {
            path: ':subMenu',
            component: ResetPasswordComponent,
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: DashboardContainerComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(resetPasswordRoutes)],
  exports: [RouterModule],
})
export class ResetPasswordRoutingModule {}
