<ng-template #downloadModal
    let-c="close"
    let-d="dismiss">
  <div *ngIf="checkPage === 'userPage'"
      class="modal-header text-center">
    <h5 class="modal-title"
        *ngIf="!isExportGoogle"
        id="modal-basic-title">
      {{"EXPORT.USER-REPORT" | translate}}
    </h5>
    <h5 class="modal-title"
        *ngIf="isExportGoogle"
        id="modal-basic-title">
      {{"TRASH.Export Memo"|translate}}
    </h5>
  </div>
  <div class="modal-body p-3">
    <div class="full-width mx-2"
        *ngIf="sendingTaskDetail._state !== celeryImportState.Failure">
      <div [style.color]="'#1958D3'"
          [style.font-size.px]="40"
          class="col-12 text-center fw-semibold">
        <div class="pt-5"
            *ngIf="sendingTaskDetail._state !== 'SUCCESS'">
          {{sendingTaskDetail.percent ? sendingTaskDetail.percent:percentDownload }}%
        </div>
        <div class="pt-5"
            *ngIf="sendingTaskDetail._state === 'SUCCESS'">
          {{'EXPORT.COMPLETED'| translate}}
        </div>
      </div>
      <div class="col-12 text-center p-3">
        {{'EXPORT.Please wait while the page is loading' | translate}}
      </div>
      <div class="pb-5 px-3">
        <ngb-progressbar [type]="currentStateProgressType"
            [value]="sendingTaskDetail.percent ? sendingTaskDetail.percent : percentDownload"
            height="1rem">
          <div class="fw-bold">
            <span class="mb-0 ms-3 me-2"
                *ngIf="sendingTaskDetail._state === celeryImportState.Progress">
              {{ sendingTaskDetail.percent }}%
            </span>
            <h5 class="mb-0 ms-3 me-2 text-white"
                *ngIf="sendingTaskDetail._state !== celeryImportState.Progress">
            </h5>
          </div>
        </ngb-progressbar>
      </div>
    </div>

    <div class="full-width mx-2 mt-4"
        *ngIf="sendingTaskDetail._state === celeryImportState.Failure">
      <div class="col-12 text-center pb-3 pt-0">
        <svg-icon src="assets/images/icons/alert/exclamation-circle-fill.svg"
            [svgStyle]="{ fill: '#f53636' }">
        </svg-icon>
      </div>
      <div class="col-12 text-center p-2 f-16">
        {{'EXPORT.Oops! An error has occurred' | translate}}
      </div>
      <div class="col-12 text-center pb-2 pt-1 f-12">
        {{'EXPORT.Your program failed to download. Please try again' | translate}}
      </div>
      <div class="p-3 text-center">
        <button class="btn btn-std-2ry me-2 "
            type="button"
            (click)="c()">
          {{"MEMOS.CANCEL" | translate}}</button>
        <button class="btn btn-std-prim"
            type="button"
            (click)="subscribe(taskId)">
          {{'EXPORT.Download Again' | translate}}
        </button>
      </div>
    </div>
  </div>
</ng-template>