import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CropperPosition,
  ImageCroppedEvent,
  ImageCropperComponent,
} from 'ngx-image-cropper';
import * as _ from 'lodash';
import { ImageProcessingService } from '@shared/service/image-processing.service';
import { base64ToFile } from '@core/services/utils.service';

@Component({
  selector: 'app-signature-cropper',
  templateUrl: './signature-cropper.component.html',
  styleUrls: ['./signature-cropper.component.scss'],
})
export class SignatureCropperComponent {
  @Input() signImageBlob: File;
  @Input() autoCrop = false;
  @Input() undoStackLength = 2;
  @Input() againText = 'SELECT-SIGN-METHOD.Sign Again';
  @Input() isModal = false;
  @Input() outputFormat: 'File' | 'Base64' = 'Base64';

  @Output() back = new EventEmitter<void>();
  @Output() next = new EventEmitter<string | File>();

  croppedImage: string;
  imageChangedEvent: any = '';

  undoStack: CropperPosition[] = [];
  redoStack: CropperPosition[] = [];
  cropper: CropperPosition = {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  };

  private imageProcessingService = inject(ImageProcessingService);

  @ViewChild('imageCropper')
  imageCropperComponent: ImageCropperComponent;

  imageLoaded(): void {
    // show cropper
  }

  loadImageFailed(): void {
    this.imageChangedEvent = '';
  }

  async onCropperReady(event: any) {
    if (!this.imageCropperComponent.imageFile) {
      throw new Error(
        `There is no 'imageFile. Please check the value of imageFile from 'image-cropper'`,
      );
    }
    this.cropper =
      await this.imageProcessingService.getSignatureBoundary(
        this.imageCropperComponent.imageFile,
      );
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64 ?? '';
    this.undoStack.push(event.cropperPosition);
    this.undoStack = _.uniqWith(this.undoStack, _.isEqual);
  }

  undoCropper() {
    if (this.undoStack.length > 1) {
      const currentState = this.undoStack.pop() as CropperPosition;
      this.redoStack.push(currentState);
      const previousState = this.undoStack[this.undoStack.length - 1];
      this.imageCropperComponent.cropper =
        this.cloneCropperPosition(previousState);
      this.imageCropperComponent.crop();
    }
  }

  redoCropper() {
    if (this.redoStack.length > 0) {
      const redoState = this.redoStack.pop() as CropperPosition;
      this.undoStack.push(redoState);
      this.imageCropperComponent.cropper =
        this.cloneCropperPosition(redoState);
      this.imageCropperComponent.crop();
    }
  }

  cloneCropperPosition(obj: CropperPosition) {
    return Object.assign({}, obj);
  }

  get disabledUndo(): boolean {
    return this.undoStack.length <= this.undoStackLength;
  }

  _back() {
    this.back.emit();
  }

  _next() {
    if (this.outputFormat === 'Base64') {
      this.next.emit(this.croppedImage);
    } else {
      const file = base64ToFile(this.croppedImage);
      this.next.emit(file);
    }
  }
}
