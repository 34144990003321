import { Component, inject, Input } from '@angular/core';
import { GroundColor } from '@shared/models/common.model';
import { UtilsService } from '../../../core/services/utils.service';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent {
  @Input() item: SnackBarItem | undefined;
  private utils = inject(UtilsService);

  get validIconPath(): string {
    if (this.item) {
      return this.utils.appendTagFromSvgPath(this.item.iconPath);
    }
    return '';
  }

  get iconBoxShadow(): string {
    return `0 3px 6px 0 ${this.item?.color.foreground}29`;
  }
}

export interface SnackBarItem {
  color: Partial<GroundColor>;
  iconPath: string;
  title: string;
  titleColor: string;
  subTitle: string;
}
