import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, map } from 'rxjs';
import { SweetAlertService } from 'src/app/shared/service/sweet-alert.service';
import * as _ from 'lodash';
import { DropdownService } from '../../service/dropdown.service';
import { AlertService } from '@core/services/alert.service';
import { Store } from '@ngxs/store';
import { People } from '../../../modules/memos/model/dropdown.model';
import { DropdownItem } from '@shared/models/common.model';
import { PersonNameDetail } from '@shared/models/user.model';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit, OnDestroy {
  readonly acceptFileType = `
      video/*,
      image/*,
      .csv,
      .ppt,
      .pptx,
      .pdf,
      application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
      application/vnd.ms-excel,
      application/msword,
      application/vnd.openxmlformats-officedocument.wordprocessingml.document
    `;

  @ViewChild('messageInput')
  messageInput: ElementRef;

  isMentionOpen = false;
  isNonUser = false;
  isLoadingComment = false;
  newComment = '';
  deBouncer = new Subject<string>();
  fileUpload: File | null = null;

  files: File[] = [];
  profileList: DropdownItem<number, PersonNameDetail>[];

  mentionConfig = {
    triggerChar: '@',
    labelKey: 'label',
    maxItems: 10,
    allowSpace: true,
    dropUp: true,
    mentionSelect: (mention: People): string => {
      return `@[${mention.value}:${mention.label}]`;
    },
  };

  @Input() memoId: number;
  @Output() submitted = new EventEmitter();
  @Output() uploaded = new EventEmitter();

  subscription: Subscription[] = [];

  private store = inject(Store);
  private alert = inject(AlertService);
  private swal = inject(SweetAlertService);
  private translate = inject(TranslateService);
  private dropdownService = inject(DropdownService);

  ngOnInit(): void {
    this.isNonUser = this.store.selectSnapshot<boolean>(
      (state) => state.auth.user_info.is_custom,
    );
    if (!this.isNonUser) {
      this.getUserList();
    }
  }

  ngOnDestroy(): void {
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    });
  }

  mentionOpened(): void {
    this.isMentionOpen = true;
  }

  mentionClosed(): void {
    this.isMentionOpen = false;
  }

  handleEnterKey(event: Event): void {
    if (!this.isMentionOpen) {
      event.preventDefault();
      this.addComment();
    }
  }

  addComment(): void {
    this.isLoadingComment = true;
    let comment;
    if (this.isNonUser) {
      comment = [{ newComment: this.newComment }];
    } else {
      const mentionedPerson: number[] = [];
      const mentions = this.messageInput.nativeElement.children;
      Array.from(mentions).forEach((mention: HTMLElement | any) => {
        const mentionID = mention.getAttribute('data-id');
        if (mentionID && !mentionedPerson.includes(mentionID)) {
          mentionedPerson.push(+mentionID);
        }
      });

      comment = [
        {
          newComment: this.newComment,
          mentionedPerson,
        },
      ];
    }
    this.submitted.emit(comment);
    setTimeout(() => {
      this.newComment = '';
      this.isLoadingComment = false;
    }, 600);
  }

  getUserList(): void {
    const params = {
      type: 'people_for_mention',
      memo_id: this.memoId.toString(),
    };
    const subscription = this.dropdownService
      .getDropdown<PersonNameDetail>(params)
      .subscribe({
        next: (dropdown) => {
          this.profileList = dropdown['people_for_mention'];
        },
        error: () => {
          const errorMsg =
            this.translate.currentLang === 'th'
              ? 'ไม่สามารถโหลดข้อมูล user สำหรับการกล่าวถึงได้'
              : `Can't get mention users.`;
          this.swal.toastNotification({
            type: 'error',
            content: errorMsg,
          });
        },
      });
    this.subscription.push(subscription);
  }

  uploadFile(fileInput: Event): void {
    const input = fileInput.target as HTMLInputElement;
    if (input.files && input.files[0].size / (1000 * 1000) >= 25) {
      this.alert.error(
        this.translate.instant(
          'MEMOS.Please upload attachment files less than 25 MB',
        ),
      );
      this.fileUpload = null;
      return;
    }
    _.map(input.files, (file) => {
      this.files.push(file);
    });
    this.uploaded.emit(this.files);
    input.value = '';
    this.files = [];
  }

  delaySearch(event: string): void {
    this.deBouncer.next(event);
  }
}
