import {
  animate,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PDFDocumentProxy, PDFSource } from 'ng2-pdf-viewer';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { ApiService } from 'src/app/core/http/api.service';
import { MemoList } from 'src/app/modules/memos/model/memo.model';
import {
  CloseMemoPreview,
  NavigateToMemoDetail,
} from '../../../store/memo-preview/memo-preview.actions';
import { Store } from '@ngxs/store';
import { MemoPreviewState } from '../../../store/memo-preview/memo-preview.state';
import { featureFlag } from '../../../../environments/environment';
import { ErrorNotification } from 'src/app/store/memo/memo.actions';
import { FileManagerService } from '@shared/service/file-manager.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-pdf-preview-std4',
  templateUrl: './modal-pdf-preview-std4.component.html',
  styleUrls: ['./modal-pdf-preview-std4.component.scss'],
  animations: [
    trigger('inOutPaneAnimation', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'translate(-50%, -55%) scale(0)',
        }),
        animate(
          '500ms ease-in-out',
          style({ opacity: 1, transform: 'translateX(10)' }),
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateX(10)' }),
        animate(
          '500ms ease-in-out',
          style({
            opacity: 0,
            transform: 'translate(-50%, -50%) scale(0)',
          }),
        ),
      ]),
    ]),
  ],
})
export class ModalPdfPreviewStd4Component implements OnInit {
  _featureFlag = featureFlag;
  memo: MemoList;
  pdfFile: PDFSource | Uint8Array | null;
  showingAttachment: boolean;
  showingViewDetail: boolean;

  pdfFileBlob?: Blob;

  @Output() viewDetail = new EventEmitter();
  @Output() viewDetailAttachment = new EventEmitter();

  @ViewChild('pdfView', { static: false })
  private pdfView: ElementRef;
  isDocumentLoadingPage: boolean;
  isRefreshPDF = false;
  currentDocumentPage: number;
  totalDocumentPage: number;
  httpHeaders;

  pageWidth: any = {};
  pageHeight: any = {};
  subscription: Subscription[] = [];
  PDF_ICON_WIDTH = 40;

  private translate = inject(TranslateService);
  private fileManager = inject(FileManagerService);

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private store: Store,
  ) {
    this.httpHeaders = this.authenticationService.httpHeader;
    this.store.select(MemoPreviewState).subscribe({
      next: (res) => {
        this.memo = res.memo;
        this.setPdfFile(res.pdfFile);
        this.httpHeaders = this.authenticationService.httpHeader;
        this.showingAttachment = res.showingAttachment;
        this.showingViewDetail = res.showingViewDetail;
      },
    });
  }

  @HostListener('document:keyup.escape', ['$event'])
  handleEscape(event: KeyboardEvent) {
    this.close();
  }

  ngOnInit(): void {
    if (this.pdfFile) {
      this.isDocumentLoadingPage = true;
    }
  }

  async setPdfFile(pdfFile: string | Blob | null): Promise<void> {
    if (!pdfFile) return;

    if (pdfFile instanceof Blob) {
      this.pdfFileBlob = pdfFile;
      this.pdfFile = new Uint8Array(await pdfFile.arrayBuffer());
    }

    if (typeof pdfFile === 'string') {
      this.pdfFile = {
        url: pdfFile,
        httpHeaders: this._featureFlag.media_url_need_authentication
          ? this.httpHeaders
          : undefined,
      };
    }
  }

  // retry or close if any error in <pdf-viewer>
  onError(): void {
    if (!this.pdfFile) {
      this.close();
      return;
    }

    if (
      'url' in this.pdfFile &&
      typeof this.pdfFile.url === 'string'
    ) {
      this.setPdfFile(this.pdfFile.url);
      return;
    }

    this.pdfFileBlob && this.setPdfFile(this.pdfFileBlob);
  }

  onPdfViewResized(event: any) {
    if (!this.isRefreshPDF && !this.isDocumentLoadingPage) {
      this.isRefreshPDF = true;
      this.isDocumentLoadingPage = true;
      setTimeout(() => {
        this.isRefreshPDF = false;
      }, 20);
    }
  }

  async onLoadPdfComplete(pdf: PDFDocumentProxy): Promise<void> {
    this.currentDocumentPage = 1;
    this.totalDocumentPage = pdf.numPages;
  }

  async pageRendered(event: any) {
    this.pageWidth[event.pageNumber] = Math.floor(event.source.width);
    this.pageHeight[event.pageNumber] = Math.floor(
      event.source.height,
    );
    this.isDocumentLoadingPage = false;
  }

  close() {
    this.store.dispatch(new CloseMemoPreview());
  }

  goToDetail() {
    this.store.dispatch(new NavigateToMemoDetail());
  }

  goToAttachment() {
    this.store.dispatch(new NavigateToMemoDetail('attachment'));
  }

  printDocument(): void {
    if (this.pdfFile instanceof Uint8Array && this.pdfFileBlob) {
      this.printBlob(this.pdfFileBlob);
      return;
    }

    this.apiService.openMediaUrlInNewWindow(
      this.memo.signed_document,
    );
  }

  downloadDocument(): void {
    if (this.pdfFile instanceof Uint8Array && this.pdfFileBlob) {
      this.downloadBlob(this.pdfFileBlob);
      return;
    }

    const subscription = this.apiService
      .printFile(this.memo.signed_document)
      .subscribe({
        next: (pdfFile: unknown) => {
          const blob = pdfFile as Blob;
          const filename = this.memo.memo_number
            ? this.memo.memo_number.replace(/\./gi, '-')
            : this.translate.instant('MEMOS.UNTITLED');
          this.fileManager.downloadFile(blob, filename);
        },
        error: (error: any) => {
          this.store.dispatch(new ErrorNotification(error));
        },
      });
    this.subscription.push(subscription);
  }

  printBlob(blob: Blob): void {
    const fileURL = URL.createObjectURL(blob);

    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);

    iframe.style.display = 'none';
    iframe.src = fileURL;
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow?.print();
      }, 1);
    };
    URL.revokeObjectURL(fileURL);
  }

  downloadBlob(blob: Blob): void {
    const fileURL = URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.href = fileURL;
    downloadLink.download =
      this.translate.instant('MEMOS.UNTITLED') + '.pdf';
    document.body.appendChild(downloadLink);

    downloadLink.click();
    URL.revokeObjectURL(fileURL);
  }

  get right() {
    if (this.pdfFile) {
      return (
        (this.pdfView?.nativeElement?.clientWidth -
          this.pageWidth[this.currentDocumentPage]) /
          2 -
        this.PDF_ICON_WIDTH
      );
    } else {
      return Math.min(
        this.pdfView?.nativeElement?.clientWidth * 0.9,
        465,
      );
    }
  }
}
