import { Directive, HostListener } from '@angular/core';
import {
  ControlContainer,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';

@Directive({
  selector: '[appFormSubmitValidation]',
})
export class FormSubmitValidationDirective {
  constructor(private control: ControlContainer) {}
  @HostListener('click', ['$event'])
  handleClickEvent() {
    const form = this.control.formDirective as FormGroupDirective;
    this.markAsTouched(form.form);
  }

  private markAsTouched(formGroup: UntypedFormGroup): void {
    formGroup.markAsTouched();
    formGroup.updateValueAndValidity();
    Object.values(formGroup.controls).forEach(
      (
        control:
          | UntypedFormArray
          | UntypedFormGroup
          | UntypedFormControl
          | any,
      ) => {
        control.markAsTouched();
        control.updateValueAndValidity({
          onlySelf: false,
          emitEvent: true,
        });
        if (control instanceof UntypedFormGroup && control.controls) {
          this.markAsTouched(control);
        }
      },
    );
  }
}
