import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/http/api.service';

@Injectable({
  providedIn: 'root',
})
export class OtpSmsService {
  constructor(private http: ApiService) {}

  send(data?: any, header?: any): any {
    return this.http.post(`/api/sms-otp/send/`, data, header);
  }

  verify(data: any): any {
    return this.http.post(`/api/sms-otp/verify/`, data);
  }

  close(data: any): any {
    return this.http.post(`/api/sms-otp/close/`, data);
  }
}
