<div class="position-relative">
  <input #datepicker="ngbDatepicker"
      id="date-picker"
      name="datepicker"
      readonly
      class="form-control input-lg w-100"
      ngbDatepicker
      autocomplete="off"
      container="body"
      [autoClose]="'outside'"
      [value]="rangeDate"
      [startDate]="startDate!"
      [minDate]="minDate"
      [maxDate]="maxDate"
      (dateSelect)="onDateSelection($event)"
      [displayMonths]="1"
      [dayTemplate]="t"
      [ngClass]="inputStyleClass"
      [placeholder]="placeholder ? (placeholder | translate) : ('MEMOS.SELECT' | translate)"
      (click)="datepicker.toggle()"
      [firstDayOfWeek]="7"
      [class.is-invalid]="invalid"
      [style.padding-right.rem]="clearable ? '4.5' : undefined"
      (closed)="onClickOutsideDatepicker()">
  <ng-template #t
    let-date
    let-focused="focused">
    <span class="custom-day"
        [class.active-datepicker-from-date.range]="compareFromDate(date)"
        [class.active-datepicker-to-date.range]="compareToDate(date)"
        [class.active-datepicker-form-date-equal-to-date.range]="formDateEqualsToDate(date)"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
      {{ date.day }}
    </span>
  </ng-template>
  <ng-template #dateFooter>
    <button type="button"
        class="btn btn-std-sm btn-std-2ry shadow-none btn-std-color-1 m-2 float-start"
        (click)="clearDate()">{{ 'MEMOS.CANCEL' | translate }}
    </button>
    <button type="button"
        class="btn btn-std-sm btn-std-prim m-2 float-end"
        (click)="datepicker?.close()">{{ 'MEMOS.DONE' | translate }}
    </button>
  </ng-template>
  <div class="container-icon">
    <span class="icon-clear ms-1">
      <svg *ngIf="clearable && (startDate || endDate)"
          class="icon-size-res"
          [ngStyle]="{'color':'#9da0ab'}"
          viewBox="0 0 24 24"
          stroke="currentColor"
          (click)="$event.stopPropagation(); clearDate()">
        <use height="24"
            width="24"
            [attr.href]="'assets/images/icons/marks/circle-xmark.svg#circle-xmark'">
        </use>
      </svg>
    </span>
    <span class="span-calendar ms-1">
      <i *ngIf="!calendarSvgPath"
          class="fa fa-calendar i-calendar"
          aria-hidden="true"
          ngbDatepicker
          (click)="datepicker.toggle()">
      </i>
      <svg *ngIf="calendarSvgPath"
          class="icon-size-res text-gray-pantone"
          viewBox="0 0 24 24"
          stroke="currentColor"
          (click)="datepicker.toggle()">
        <use height="24"
            width="24"
            [attr.href]="calendarSvgPath">
        </use>
      </svg>
    </span>
  </div>
</div>