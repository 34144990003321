import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  DropdownPosition,
  NgSelectModule,
} from '@ng-select/ng-select';
import { DropdownItem } from '@shared/models/common.model';
import { ZoomScale } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-pdf-page-controller',
  standalone: true,
  imports: [CommonModule, FormsModule, NgSelectModule],
  templateUrl: './pdf-page-controller.component.html',
  styleUrls: ['./pdf-page-controller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfPageControllerComponent implements OnChanges {
  @Input() dropdownPosition: DropdownPosition = 'auto';
  @Input() page?: number | null;
  /** The actual scale getting from rendering used for forward/backward fit scales. */
  @Input() renderedScale?: number | null;
  @Input() scale: number | string = 1;
  @Input() scaleChoices: number[] = [0.5, 1, 1.5, 2];
  @Input() scaleFeature: ScaleFeature = 'enable';
  @Input() totalPage = 0;
  @Output() pageChange = new EventEmitter<
    number | undefined | null
  >();
  @Output() scaleChange = new EventEmitter<number | ZoomScale>();

  scaleDropdownChoices: ScaleDropdownItem[] = [];

  private previousPage?: number | null;

  constructor() {
    this.initializeScaleDropdownChoices();
    this.previousPage = this.page;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['scaleChoices']) {
      this.initializeScaleDropdownChoices();
    }
    if (changes['page']) {
      let page = changes['page'].currentValue as
        | number
        | undefined
        | null;
      page = this.validatePage(page);
      this.previousPage = page;
    }
  }

  changeScale(change: 'up' | 'down') {
    let index: number | undefined;
    const actualScale =
      typeof this.scale === 'number'
        ? this.scale
        : this.renderedScale;
    for (let i = 0; i < this.scaleChoices.length; i++) {
      if (this.scaleChoices[i] === actualScale) {
        index = i;
        break;
      } else if (actualScale && this.scaleChoices[i] > actualScale) {
        index = change === 'up' ? i - 1 : i;
        break;
      }
    }
    let finalScale: number | string;
    if (index == null) {
      finalScale = 1;
    } else {
      const nextIndex = change === 'up' ? index + 1 : index - 1;
      if (nextIndex >= this.scaleChoices.length || nextIndex < 0) {
        finalScale = this.scaleChoices[index];
      } else {
        finalScale = this.scaleChoices[nextIndex];
      }
    }
    this.scale = finalScale;
    this.scaleChange.emit(finalScale);
  }

  initializeScaleDropdownChoices(): void {
    this.scaleChoices.sort((a, b) => a - b);
    this.scaleDropdownChoices = this.scaleChoices.map((scale) => ({
      label: (Math.round(scale * 10000) / 100).toString() + '%',
      value: scale,
      scaleType: 'static',
    }));
    const fitChoices: ScaleDropdownItem[] = [
      {
        label: 'Fit Page',
        value: 'page-fit',
        scaleType: 'dynamic',
      },
      {
        label: 'Fit Width',
        value: 'page-width',
        scaleType: 'dynamic',
      },
    ];
    this.scaleDropdownChoices.unshift(...fitChoices);
  }

  onScaleChange(scale: number | ZoomScale): void {
    this.scaleChange.emit(scale);
  }

  validatePage(
    page: number | undefined | null,
  ): number | undefined | null {
    if (page != null) {
      if (!this.totalPage) {
        return 0;
      }
      let reverse = false;
      if (page < 0) {
        reverse = true;
        page = Math.abs(page);
      }
      page = ((page - 1) % this.totalPage) + 1;
      if (reverse) {
        page = this.totalPage - page + 1;
      }
    }
    return page;
  }

  onPageSubmit(): void {
    const page = this.validatePage(this.page);
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.pageChange.emit(page);
    }
  }
}

type ScaleDropdownItem = DropdownItem<number | string> & {
  scaleType: 'static' | 'dynamic';
};
export type ScaleFeature = 'enable' | 'disable' | 'hide';
