import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  ClearSelectAnnouncement,
  ShowAnnouncementDetail,
} from './new-feed-announcement.actions';
import { NewFeedAnnouncementStateModel } from './new-feed-announcement.models';
import { NotificationService } from '@shared/service/notification.service';

@State<NewFeedAnnouncementStateModel>({
  name: 'newFeedAnnouncement',
  defaults: {
    announcement: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class NewFeedAnnouncementState {
  constructor(public notificationService: NotificationService) {}

  @Selector()
  static announcementList(state: NewFeedAnnouncementStateModel) {
    return state;
  }

  @Action(ShowAnnouncementDetail)
  showAnnouncementDetail(
    ctx: StateContext<ShowAnnouncementDetail>,
    action: NewFeedAnnouncementStateModel,
  ) {
    if (action.announcement) {
      ctx.setState({
        announcement: action.announcement,
      });
    }
  }

  @Action(ClearSelectAnnouncement)
  clearSelectMemo({
    patchState,
  }: StateContext<NewFeedAnnouncementStateModel>) {
    patchState({
      announcement: null,
    });
  }
}
