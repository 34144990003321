import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { AlertService } from '@core/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { OtpModalComponent } from '../otp-modal/otp-modal.component';
import { Person } from '@shared/models/user.model';
import { AuthState } from '../../../store/auth/auth.state';
import { Store } from '@ngxs/store';

type signatureStep = 'select' | 'text' | 'upload' | 'sign' | 'crop';
type signMode = 'text' | 'upload' | 'sign';

@Component({
  selector: 'app-select-sign-method-modal',
  templateUrl: './select-sign-method-modal.component.html',
  styleUrls: ['./select-sign-method-modal.component.scss'],
})
export class SelectSignMethodModalComponent {
  @Input() customTextSignature = false;
  @Input() enableUploadSignature = false;
  @Input() enableUsingStoredSignature = true;
  @Input() enableTextSignature = false;

  @Input() isSaved: boolean;
  @Input() otpEnable: boolean;

  @Input() header: any;
  @Input() numberOfSignature: number | any = null;
  @Input() countPage: number;

  @Input() otp: string;
  @Output() otpChange = new EventEmitter<string>();

  @Input() signature: File;
  @Output() signatureChange = new EventEmitter<File>();

  @Input() signaturePreview: string;

  @Output() confirm = new EventEmitter();
  @Output() closeModal = new EventEmitter();

  @Input() isUseStored: boolean;
  @Output() isUseStoredChange = new EventEmitter();

  @ViewChild('selectSignMethodModal', { static: false })
  selectSignMethodModal: ElementRef;
  @ViewChild(OtpModalComponent)
  otpModalComponent: OtpModalComponent;

  @Input() isUploadOther = false;

  selectSignMethodModalRef: NgbModalRef;

  step: signatureStep = 'select';
  mode: signMode;
  signImage: File | null;
  uploadOriginalFileName: string | undefined;
  profile: Person;

  constructor(
    public modalService: NgbModal,
    private alert: AlertService,
    public translate: TranslateService,
    private store: Store,
  ) {
    this.store.select(AuthState).subscribe({
      next: (res) => {
        this.profile = res.user_info;
      },
    });
  }

  openOtpModal(): void {
    this.modalService.dismissAll();
    this.otpModalComponent.openModal();
  }

  openSelectSignMethodModal(): void {
    this.select();
    this.openModal();
  }

  openModal() {
    this.selectSignMethodModalRef = this.modalService.open(
      this.selectSignMethodModal,
      {
        backdrop: 'static',
        size: 'size-smax56',
        centered: true,
      },
    );
  }

  select() {
    this.signImage = null;
    this.step = 'select';
  }

  signClick(): void {
    this.step = 'sign';
    this.mode = 'sign';
  }

  textClick(): void {
    this.step = 'text';
    this.mode = 'text';
  }

  uploadClick(): void {
    this.step = 'upload';
    this.mode = 'upload';
  }

  crop(event: File) {
    this.signImage = event;
    this.step = 'crop';
  }

  openUploadUserSignature(event: any): void {
    this.mode = 'upload';
    this.openModal();
    this.crop(event.target.files[0]);
  }

  _back() {
    if (['text', 'upload', 'sign'].includes(this.step)) {
      this.select();
    } else if (this.step == 'crop') {
      this.mode === 'sign' ? this.signClick() : this.uploadClick();
    }
  }

  save(event: File) {
    this.signatureChange.emit(event);
    if (this.otpEnable) {
      this.openOtpModal();
      return;
    }
    this.submit();
  }

  submit(useStoredSignature = false): void {
    this.isUseStoredChange.emit(useStoredSignature);
    this.confirm.emit(this.uploadOriginalFileName);
  }

  close(): void {
    this.modalService.dismissAll();
    this.closeModal.emit();
  }

  verifyOTP(): void {
    if (this.otpModalComponent.invalidOTP) {
      this.alert.error('Input OTP');
      return;
    }
    this.otpChange.emit(this.otp);
    this.submit();
  }

  get title(): string {
    switch (this.step) {
      case 'select':
        return 'SELECT-SIGN-METHOD.QUESTION';
      case 'upload':
        return 'UPLOAD.Upload file';
      case 'text':
        return 'SELECT-SIGN-METHOD.SIGN-BY-TYPING';
      case 'sign':
        return 'SELECT-SIGN-METHOD.SIGN-NOW';
      case 'crop':
        return 'APPROVAL.CONFIRM-SIGNATURE';
    }
  }

  get col(): string {
    let count = 4;
    if (!this.enableTextSignature) {
      count--;
    }
    if (!this.enableUsingStoredSignature) {
      count--;
    }
    return count === 4 ? 'col-md-6' : 'col-md';
  }
}
