<ng-template #content let-modal>
    <div class="icon"> <img src="assets/images/icons/close.svg" (click)="modal.close('')" alt="">
    </div>
    <div class="icon" style="top: 40px;">
        <img src="assets/images/icons/print.svg" alt="" (click)="print()"></div>
    <div class="modal-body">
        <h2>PDF Viewer</h2>

        <div class="pdf-container">
            <pdf-viewer [src]="(src | secure | async) || undefined"
                [c-maps-url]="'/assets/cmaps/'"
                [original-size]="true"></pdf-viewer>
        </div>

    </div>
</ng-template>
