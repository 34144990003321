import { nanoid } from 'nanoid';
import { Marker } from '../components/upload-memos/pdf-signature-customizer/pdf-signature-customizer.model';

export const transformMarkerVersion1Into4 = (
  markers: Marker[],
): Marker[] => {
  markers.forEach((marker) => {
    const identityKeys = [
      'positions',
      'approved_date_positions',
      'comment_positions',
    ] as const;
    const identityGroupMap: { [id: string]: string } = {};
    identityKeys.forEach((key) => {
      const identities = marker[key];
      identities?.forEach((identity) => {
        if (!identity.id) {
          // TODO:
          return;
        }
        let identityGroup = identityGroupMap[identity.id];
        if (identityGroup == null) {
          identityGroup = nanoid(5);
          identity.pageIdList?.forEach((pageId) => {
            identityGroupMap[pageId.id] = identityGroup;
          });
        }
        identity.group = identityGroup;
        delete identity.pageIdList;
        delete identity.pageList;
      });
    });
  });
  return markers;
};

export const getTransformedMarkerByVersion = (
  markers: Marker[],
  markerVersion: string,
): Marker[] => {
  const isOneToFour = !!markerVersion.match(/^1\..+$/);
  if (isOneToFour) {
    return transformMarkerVersion1Into4(markers);
  }
  return markers;
};
