import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileManagerService {
  constructor() {}

  base64ToFile(data: string, filename = 'photo.png') {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)?.[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  base64ToBinary(base64String: string): Uint8Array {
    const byteString = atob(base64String.split(',')[1]);
    const byteArray = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    return byteArray;
  }

  blobToFile(blob: Blob, filename = 'photo.png') {
    return new File([blob], filename, {
      type: blob?.type,
    });
  }

  downloadFile(blob: Blob, filename: string) {
    const url = URL.createObjectURL(blob);
    this.downloadUrlFile(url, filename);
    URL.revokeObjectURL(url);
  }

  downloadUrlFile(
    url: string,
    filename: string,
    anchorTarget = '_self',
  ) {
    const element = document.createElement('a');
    document.body.appendChild(element);
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    element.target = anchorTarget;
    element.click();
    document.body.removeChild(element);
  }

  fileListToArr(fileList: FileList): Array<File> {
    const files: File[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      if (file != null) files.push(file);
    }
    return files;
  }

  getFileToUrl(
    file: Blob | File,
  ): Observable<string | ArrayBuffer | null> {
    const reader = new FileReader();
    const obs = new Observable<FileReader['result']>((subscriber) => {
      reader.onload = (event) => {
        subscriber.next(event.target?.result);
        subscriber.complete();
      };
    });
    reader.readAsDataURL(file);
    return obs;
  }

  getFileToArrayBuffer(
    file: Blob | File,
  ): Observable<ArrayBuffer | null> {
    const reader = new FileReader();
    const obs = new Observable<Exclude<FileReader['result'], string>>(
      (subscriber) => {
        reader.onloadend = (event) => {
          subscriber.next(
            event.target?.result as ArrayBuffer | undefined | null,
          );
          subscriber.complete();
        };
      },
    );
    reader.readAsArrayBuffer(file);
    return obs;
  }
}
