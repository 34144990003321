import { CountryCode } from '@shared/models/common.model';

export const COUNTRY_CODES: CountryCode[] = [
  {
    code: '+66',
    icon: '🇹🇭',
    masks: [
      {
        mask: '00-000-000',
      },
      {
        mask: '00-000-0000',
      },
      {
        mask: '090-000-0000',
      },
    ],
    name: 'Thailand',
  },
  {
    code: '+376',
    icon: '🇦🇩',
    masks: [
      {
        mask: '000-000',
      },
    ],
    name: 'Andorra',
  },
  {
    code: '+971',
    icon: '🇦🇪',
    masks: [
      {
        mask: '0-000-0000',
      },
      {
        mask: '50-000-0000',
      },
    ],
    name: 'United Arab Emirates',
  },
  {
    code: '+93',
    icon: '🇦🇫',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Afghanistan',
  },
  {
    code: '+1',
    icon: '🇦🇬',
    masks: [
      {
        mask: '(268)000-0000',
      },
    ],
    name: 'Antigua and Barbuda',
  },
  {
    code: '+1',
    icon: '🇦🇮',
    masks: [
      {
        mask: '(264)000-0000',
      },
    ],
    name: 'Anguilla',
  },
  {
    code: '+355',
    icon: '🇦🇱',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Albania',
  },
  {
    code: '+374',
    icon: '🇦🇲',
    masks: [
      {
        mask: '00-000-000',
      },
    ],
    name: 'Armenia',
  },
  {
    code: '+244',
    icon: '🇦🇴',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Angola',
  },
  {
    code: '+672',
    icon: '🇦🇶',
    masks: [
      {
        mask: '100-000',
      },
    ],
    name: 'Antarctica',
  },
  {
    code: '+54',
    icon: '🇦🇷',
    masks: [
      {
        mask: '(000)000-0000',
      },
    ],
    name: 'Argentina',
  },
  {
    code: '+1',
    icon: '🇦🇸',
    masks: [
      {
        mask: '(684)000-0000',
      },
    ],
    name: 'American Samoa',
  },
  {
    code: '+43',
    icon: '🇦🇹',
    masks: [
      {
        mask: '(000)000-0000',
      },
    ],
    name: 'Austria',
  },
  {
    code: '+61',
    icon: '🇦🇺',
    masks: [
      {
        mask: '0-0000-0000',
      },
    ],
    name: 'Australia',
  },
  {
    code: '+297',
    icon: '🇦🇼',
    masks: [
      {
        mask: '000-0000',
      },
    ],
    name: 'Aruba',
  },
  {
    code: '+358',
    icon: '🇦🇽',
    masks: [
      {
        mask: '(000)000-00-00',
      },
    ],
    name: 'Aland Islands',
  },
  {
    code: '+994',
    icon: '🇦🇿',
    masks: [
      {
        mask: '00-000-00-00',
      },
    ],
    name: 'Azerbaijan',
  },
  {
    code: '+387',
    icon: '🇧🇦',
    masks: [
      {
        mask: '00-0000',
      },
      {
        mask: '00-00000',
      },
    ],
    name: 'Bosnia and Herzegovina',
  },
  {
    code: '+1',
    icon: '🇧🇧',
    masks: [
      {
        mask: '(246)000-0000',
      },
    ],
    name: 'Barbados',
  },
  {
    code: '+880',
    icon: '🇧🇩',
    masks: [
      {
        mask: '1000-000000',
      },
    ],
    name: 'Bangladesh',
  },
  {
    code: '+32',
    icon: '🇧🇪',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Belgium',
  },
  {
    code: '+226',
    icon: '🇧🇫',
    masks: [
      {
        mask: '00-00-0000',
      },
    ],
    name: 'Burkina Faso',
  },
  {
    code: '+359',
    icon: '🇧🇬',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Bulgaria',
  },
  {
    code: '+973',
    icon: '🇧🇭',
    masks: [
      {
        mask: '0000-0000',
      },
    ],
    name: 'Bahrain',
  },
  {
    code: '+257',
    icon: '🇧🇮',
    masks: [
      {
        mask: '00-00-0000',
      },
    ],
    name: 'Burundi',
  },
  {
    code: '+229',
    icon: '🇧🇯',
    masks: [
      {
        mask: '00-00-0000',
      },
    ],
    name: 'Benin',
  },
  {
    code: '+590',
    icon: '🇧🇱',
    masks: [
      {
        mask: '000-00-00-00',
      },
    ],
    name: 'Saint Barthelemy',
  },
  {
    code: '+1',
    icon: '🇧🇲',
    masks: [
      {
        mask: '(441)000-0000',
      },
    ],
    name: 'Bermuda',
  },
  {
    code: '+673',
    icon: '🇧🇳',
    masks: [
      {
        mask: '000-0000',
      },
    ],
    name: 'Brunei Darussalam',
  },
  {
    code: '+591',
    icon: '🇧🇴',
    masks: [
      {
        mask: '0-000-0000',
      },
    ],
    name: 'Bolivia',
  },
  {
    code: '+55',
    icon: '🇧🇷',
    masks: [
      {
        mask: '(00)0000-0000',
      },
      {
        mask: '(00)00000-0000',
      },
    ],
    name: 'Brazil',
  },
  {
    code: '+1',
    icon: '🇧🇸',
    masks: [
      {
        mask: '(242)000-0000',
      },
    ],
    name: 'Bahamas',
  },
  {
    code: '+975',
    icon: '🇧🇹',
    masks: [
      {
        mask: '17-000-000',
      },
      {
        mask: '77-000-000',
      },
      {
        mask: '0-000-000',
      },
    ],
    name: 'Bhutan',
  },
  {
    code: '+267',
    icon: '🇧🇼',
    masks: [
      {
        mask: '00-000-000',
      },
    ],
    name: 'Botswana',
  },
  {
    code: '+375',
    icon: '🇧🇾',
    masks: [
      {
        mask: '(00)000-00-00',
      },
    ],
    name: 'Belarus',
  },
  {
    code: '+501',
    icon: '🇧🇿',
    masks: [
      {
        mask: '000-0000',
      },
    ],
    name: 'Belize',
  },
  {
    code: '+1',
    icon: '🇨🇦',
    masks: [
      {
        mask: '(000)000-0000',
      },
    ],
    name: 'Canada',
  },
  {
    code: '+61',
    icon: '🇨🇨',
    masks: [
      {
        mask: '0-0000-0000',
      },
    ],
    name: 'Cocos (Keeling) Islands',
  },
  {
    code: '+243',
    icon: '🇨🇩',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Democratic Republic of the Congo',
  },
  {
    code: '+236',
    icon: '🇨🇫',
    masks: [
      {
        mask: '00-00-0000',
      },
    ],
    name: 'Central African Republic',
  },
  {
    code: '+242',
    icon: '🇨🇬',
    masks: [
      {
        mask: '00-00000',
      },
    ],
    name: 'Congo',
  },
  {
    code: '+41',
    icon: '🇨🇭',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Switzerland',
  },
  {
    code: '+225',
    icon: '🇨🇮',
    masks: [
      {
        mask: '00-000-000',
      },
    ],
    name: "Ivory·Coast·/·Cote·d'Ivoire" /* eslint-disable-line quotes */,
  },
  {
    code: '+682',
    icon: '🇨🇰',
    masks: [
      {
        mask: '00-000',
      },
    ],
    name: 'Cook Islands',
  },
  {
    code: '+56',
    icon: '🇨🇱',
    masks: [
      {
        mask: '0-0000-0000',
      },
    ],
    name: 'Chile',
  },
  {
    code: '+237',
    icon: '🇨🇲',
    masks: [
      {
        mask: '0000-0000',
      },
    ],
    name: 'Cameroon',
  },
  {
    code: '+86',
    icon: '🇨🇳',
    masks: [
      {
        mask: '(000)0000-000',
      },
      {
        mask: '(000)0000-0000',
      },
      {
        mask: '00-00000-00000',
      },
    ],
    name: 'China',
  },
  {
    code: '+57',
    icon: '🇨🇴',
    masks: [
      {
        mask: '(000)000-0000',
      },
    ],
    name: 'Colombia',
  },
  {
    code: '+506',
    icon: '🇨🇷',
    masks: [
      {
        mask: '0000-0000',
      },
    ],
    name: 'Costa Rica',
  },
  {
    code: '+53',
    icon: '🇨🇺',
    masks: [
      {
        mask: '0-000-0000',
      },
    ],
    name: 'Cuba',
  },
  {
    code: '+238',
    icon: '🇨🇻',
    masks: [
      {
        mask: '(000)00-00',
      },
    ],
    name: 'Cape Verde',
  },
  {
    code: '+61',
    icon: '🇨🇽',
    masks: [
      {
        mask: '0-0000-0000',
      },
    ],
    name: 'Christmas Island',
  },
  {
    code: '+357',
    icon: '🇨🇾',
    masks: [
      {
        mask: '00-000-000',
      },
    ],
    name: 'Cyprus',
  },
  {
    code: '+420',
    icon: '🇨🇿',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Czech Republic',
  },
  {
    code: '+49',
    icon: '🇩🇪',
    masks: [
      {
        mask: '000-000',
      },
      {
        mask: '(000)00-00',
      },
      {
        mask: '(000)00-000',
      },
      {
        mask: '(000)00-0000',
      },
      {
        mask: '(000)000-0000',
      },
      {
        mask: '(0000)000-0000',
      },
    ],
    name: 'Germany',
  },
  {
    code: '+253',
    icon: '🇩🇯',
    masks: [
      {
        mask: '00-00-00-00',
      },
    ],
    name: 'Djibouti',
  },
  {
    code: '+45',
    icon: '🇩🇰',
    masks: [
      {
        mask: '00-00-00-00',
      },
    ],
    name: 'Denmark',
  },
  {
    code: '+1',
    icon: '🇩🇲',
    masks: [
      {
        mask: '(767)000-0000',
      },
    ],
    name: 'Dominica',
  },
  {
    code: '+1',
    icon: '🇩🇴',
    masks: [
      {
        mask: '(809)000-0000',
      },
      {
        mask: '(829)000-0000',
      },
      {
        mask: '(849)000-0000',
      },
    ],
    name: 'Dominican Republic',
  },
  {
    code: '+213',
    icon: '🇩🇿',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Algeria',
  },
  {
    code: '+593',
    icon: '🇪🇨',
    masks: [
      {
        mask: '0-000-0000',
      },
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Ecuador',
  },
  {
    code: '+372',
    icon: '🇪🇪',
    masks: [
      {
        mask: '000-0000',
      },
      {
        mask: '0000-0000',
      },
    ],
    name: 'Estonia',
  },
  {
    code: '+20',
    icon: '🇪🇬',
    masks: [
      {
        mask: '(000)000-0000',
      },
    ],
    name: 'Egypt',
  },
  {
    code: '+291',
    icon: '🇪🇷',
    masks: [
      {
        mask: '0-000-000',
      },
    ],
    name: 'Eritrea',
  },
  {
    code: '+34',
    icon: '🇪🇸',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Spain',
  },
  {
    code: '+251',
    icon: '🇪🇹',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Ethiopia',
  },
  {
    code: '+358',
    icon: '🇫🇮',
    masks: [
      {
        mask: '(000)000-00-00',
      },
    ],
    name: 'Finland',
  },
  {
    code: '+679',
    icon: '🇫🇯',
    masks: [
      {
        mask: '00-00000',
      },
    ],
    name: 'Fiji',
  },
  {
    code: '+500',
    icon: '🇫🇰',
    masks: [
      {
        mask: '00000',
      },
    ],
    name: 'Falkland Islands (Malvinas)',
  },
  {
    code: '+691',
    icon: '🇫🇲',
    masks: [
      {
        mask: '000-0000',
      },
    ],
    name: 'Micronesia, Federated States of Micronesia',
  },
  {
    code: '+298',
    icon: '🇫🇴',
    masks: [
      {
        mask: '000-000',
      },
    ],
    name: 'Faroe Islands',
  },
  {
    code: '+33',
    icon: '🇫🇷',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'France',
  },
  {
    code: '+241',
    icon: '🇬🇦',
    masks: [
      {
        mask: '0-00-00-00',
      },
    ],
    name: 'Gabon',
  },
  {
    code: '+44',
    icon: '🇬🇧',
    masks: [
      {
        mask: '00-0000-0000',
      },
    ],
    name: 'United Kingdom',
  },
  {
    code: '+1',
    icon: '🇬🇩',
    masks: [
      {
        mask: '(473)000-0000',
      },
    ],
    name: 'Grenada',
  },
  {
    code: '+995',
    icon: '🇬🇪',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Georgia',
  },
  {
    code: '+594',
    icon: '🇬🇫',
    masks: [
      {
        mask: '00000-0000',
      },
    ],
    name: 'French Guiana',
  },
  {
    code: '+44',
    icon: '🇬🇬',
    masks: [
      {
        mask: '(0000)000000',
      },
    ],
    name: 'Guernsey',
  },
  {
    code: '+233',
    icon: '🇬🇭',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Ghana',
  },
  {
    code: '+350',
    icon: '🇬🇮',
    masks: [
      {
        mask: '000-00000',
      },
    ],
    name: 'Gibraltar',
  },
  {
    code: '+299',
    icon: '🇬🇱',
    masks: [
      {
        mask: '00-00-00',
      },
    ],
    name: 'Greenland',
  },
  {
    code: '+220',
    icon: '🇬🇲',
    masks: [
      {
        mask: '(000)00-00',
      },
    ],
    name: 'Gambia',
  },
  {
    code: '+224',
    icon: '🇬🇳',
    masks: [
      {
        mask: '00-000-000',
      },
    ],
    name: 'Guinea',
  },
  {
    code: '+590',
    icon: '🇬🇵',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Guadeloupe',
  },
  {
    code: '+240',
    icon: '🇬🇶',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Equatorial Guinea',
  },
  {
    code: '+30',
    icon: '🇬🇷',
    masks: [
      {
        mask: '(000)000-0000',
      },
    ],
    name: 'Greece',
  },
  {
    code: '+500',
    icon: '🇬🇸',
    masks: [
      {
        mask: '00000',
      },
    ],
    name: 'South Georgia and the South Sandwich Islands',
  },
  {
    code: '+502',
    icon: '🇬🇹',
    masks: [
      {
        mask: '0-000-0000',
      },
    ],
    name: 'Guatemala',
  },
  {
    code: '+1',
    icon: '🇬🇺',
    masks: [
      {
        mask: '(671)000-0000',
      },
    ],
    name: 'Guam',
  },
  {
    code: '+245',
    icon: '🇬🇼',
    masks: [
      {
        mask: '0-000000',
      },
    ],
    name: 'Guinea-Bissau',
  },
  {
    code: '+592',
    icon: '🇬🇾',
    masks: [
      {
        mask: '000-0000',
      },
    ],
    name: 'Guyana',
  },
  {
    code: '+852',
    icon: '🇭🇰',
    masks: [
      {
        mask: '0000-0000',
      },
    ],
    name: 'Hong Kong',
  },
  {
    code: '+504',
    icon: '🇭🇳',
    masks: [
      {
        mask: '0000-0000',
      },
    ],
    name: 'Honduras',
  },
  {
    code: '+385',
    icon: '🇭🇷',
    masks: [
      {
        mask: '00-000-000',
      },
    ],
    name: 'Croatia',
  },
  {
    code: '+509',
    icon: '🇭🇹',
    masks: [
      {
        mask: '00-00-0000',
      },
    ],
    name: 'Haiti',
  },
  {
    code: '+36',
    icon: '🇭🇺',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Hungary',
  },
  {
    code: '+62',
    icon: '🇮🇩',
    masks: [
      {
        mask: '00-000-00',
      },
      {
        mask: '00-000-000',
      },
      {
        mask: '00-000-0000',
      },
      {
        mask: '(800)000-000',
      },
      {
        mask: '(800)000-00-000',
      },
    ],
    name: 'Indonesia',
  },
  {
    code: '+353',
    icon: '🇮🇪',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Ireland',
  },
  {
    code: '+972',
    icon: '🇮🇱',
    masks: [
      {
        mask: '0-000-0000',
      },
      {
        mask: '50-000-0000',
      },
    ],
    name: 'Israel',
  },
  {
    code: '+44',
    icon: '🇮🇲',
    masks: [
      {
        mask: '(0000)000000',
      },
    ],
    name: 'Isle of Man',
  },
  {
    code: '+91',
    icon: '🇮🇳',
    masks: [
      {
        mask: '(0000)000-000',
      },
    ],
    name: 'India',
  },
  {
    code: '+246',
    icon: '🇮🇴',
    masks: [
      {
        mask: '000-0000',
      },
    ],
    name: 'British Indian Ocean Territory',
  },
  {
    code: '+964',
    icon: '🇮🇶',
    masks: [
      {
        mask: '(000)000-0000',
      },
    ],
    name: 'Iraq',
  },
  {
    code: '+98',
    icon: '🇮🇷',
    masks: [
      {
        mask: '(000)000-0000',
      },
    ],
    name: 'Iran',
  },
  {
    code: '+354',
    icon: '🇮🇸',
    masks: [
      {
        mask: '000-0000',
      },
    ],
    name: 'Iceland',
  },
  {
    code: '+39',
    icon: '🇮🇹',
    masks: [
      {
        mask: '(000)0000-000',
      },
    ],
    name: 'Italy',
  },
  {
    code: '+44',
    icon: '🇯🇪',
    masks: [
      {
        mask: '(0000)0000-000000',
      },
    ],
    name: 'Jersey',
  },
  {
    code: '+1',
    icon: '🇯🇲',
    masks: [
      {
        mask: '(876)000-0000',
      },
    ],
    name: 'Jamaica',
  },
  {
    code: '+962',
    icon: '🇯🇴',
    masks: [
      {
        mask: '0-0000-0000',
      },
    ],
    name: 'Jordan',
  },
  {
    code: '+81',
    icon: '🇯🇵',
    masks: [
      {
        mask: '(000)000-000',
      },
      {
        mask: '00-0000-0000',
      },
    ],
    name: 'Japan',
  },
  {
    code: '+254',
    icon: '🇰🇪',
    masks: [
      {
        mask: '000-000000',
      },
    ],
    name: 'Kenya',
  },
  {
    code: '+996',
    icon: '🇰🇬',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Kyrgyzstan',
  },
  {
    code: '+855',
    icon: '🇰🇭',
    masks: [
      {
        mask: '00-000-000',
      },
    ],
    name: 'Cambodia',
  },
  {
    code: '+686',
    icon: '🇰🇮',
    masks: [
      {
        mask: '00-000',
      },
    ],
    name: 'Kiribati',
  },
  {
    code: '+269',
    icon: '🇰🇲',
    masks: [
      {
        mask: '00-00000',
      },
    ],
    name: 'Comoros',
  },
  {
    code: '+1',
    icon: '🇰🇳',
    masks: [
      {
        mask: '(869)000-0000',
      },
    ],
    name: 'Saint Kitts and Nevis',
  },
  {
    code: '+850',
    icon: '🇰🇵',
    masks: [
      {
        mask: '000-000',
      },
      {
        mask: '0000-0000',
      },
      {
        mask: '00-000-000',
      },
      {
        mask: '000-0000-000',
      },
      {
        mask: '191-000-0000',
      },
      {
        mask: '0000-0000000000000',
      },
    ],
    name: "Korea,·Democratic·People's·Republic·of·Korea" /* eslint-disable-line quotes */,
  },
  {
    code: '+82',
    icon: '🇰🇷',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Korea, Republic of South Korea',
  },
  {
    code: '+965',
    icon: '🇰🇼',
    masks: [
      {
        mask: '0000-0000',
      },
    ],
    name: 'Kuwait',
  },
  {
    code: '+1',
    icon: '🇰🇾',
    masks: [
      {
        mask: '(345)000-0000',
      },
    ],
    name: 'Cayman Islands',
  },
  {
    code: '+77',
    icon: '🇰🇿',
    masks: [
      {
        mask: '(600)000-00-00',
      },
      {
        mask: '(700)000-00-00',
      },
    ],
    name: 'Kazakhstan',
  },
  {
    code: '+856',
    icon: '🇱🇦',
    masks: [
      {
        mask: '00-000-000',
      },
      {
        mask: '(2000)000-000',
      },
    ],
    name: 'Laos',
  },
  {
    code: '+961',
    icon: '🇱🇧',
    masks: [
      {
        mask: '0-000-000',
      },
      {
        mask: '00-000-000',
      },
    ],
    name: 'Lebanon',
  },
  {
    code: '+1',
    icon: '🇱🇨',
    masks: [
      {
        mask: '(758)000-0000',
      },
    ],
    name: 'Saint Lucia',
  },
  {
    code: '+423',
    icon: '🇱🇮',
    masks: [
      {
        mask: '(000)000-0000',
      },
    ],
    name: 'Liechtenstein',
  },
  {
    code: '+94',
    icon: '🇱🇰',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Sri Lanka',
  },
  {
    code: '+231',
    icon: '🇱🇷',
    masks: [
      {
        mask: '00-000-000',
      },
    ],
    name: 'Liberia',
  },
  {
    code: '+266',
    icon: '🇱🇸',
    masks: [
      {
        mask: '0-000-0000',
      },
    ],
    name: 'Lesotho',
  },
  {
    code: '+370',
    icon: '🇱🇹',
    masks: [
      {
        mask: '(000)00-000',
      },
    ],
    name: 'Lithuania',
  },
  {
    code: '+352',
    icon: '🇱🇺',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Luxembourg',
  },
  {
    code: '+371',
    icon: '🇱🇻',
    masks: [
      {
        mask: '00-000-000',
      },
    ],
    name: 'Latvia',
  },
  {
    code: '+218',
    icon: '🇱🇾',
    masks: [
      {
        mask: '00-000-000',
      },
      {
        mask: '21-000-0000',
      },
    ],
    name: 'Libya',
  },
  {
    code: '+212',
    icon: '🇲🇦',
    masks: [
      {
        mask: '00-0000-000',
      },
    ],
    name: 'Morocco',
  },
  {
    code: '+377',
    icon: '🇲🇨',
    masks: [
      {
        mask: '00-000-000',
      },
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Monaco',
  },
  {
    code: '+373',
    icon: '🇲🇩',
    masks: [
      {
        mask: '0000-0000',
      },
    ],
    name: 'Moldova',
  },
  {
    code: '+382',
    icon: '🇲🇪',
    masks: [
      {
        mask: '00-000-000',
      },
    ],
    name: 'Montenegro',
  },
  {
    code: '+590',
    icon: '🇲🇫',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Saint Martin',
  },
  {
    code: '+261',
    icon: '🇲🇬',
    masks: [
      {
        mask: '00-00-00000',
      },
    ],
    name: 'Madagascar',
  },
  {
    code: '+692',
    icon: '🇲🇭',
    masks: [
      {
        mask: '000-0000',
      },
    ],
    name: 'Marshall Islands',
  },
  {
    code: '+389',
    icon: '🇲🇰',
    masks: [
      {
        mask: '00-000-000',
      },
    ],
    name: 'North Macedonia',
  },
  {
    code: '+223',
    icon: '🇲🇱',
    masks: [
      {
        mask: '00-00-0000',
      },
    ],
    name: 'Mali',
  },
  {
    code: '+95',
    icon: '🇲🇲',
    masks: [
      {
        mask: '000-000',
      },
      {
        mask: '0-000-000',
      },
      {
        mask: '00-000-000',
      },
      {
        mask: '000-000-0000',
      },
    ],
    name: 'Myanmar',
  },
  {
    code: '+976',
    icon: '🇲🇳',
    masks: [
      {
        mask: '00-00-0000',
      },
    ],
    name: 'Mongolia',
  },
  {
    code: '+853',
    icon: '🇲🇴',
    masks: [
      {
        mask: '0000-0000',
      },
    ],
    name: 'Macau',
  },
  {
    code: '+1',
    icon: '🇲🇵',
    masks: [
      {
        mask: '(670)000-0000',
      },
    ],
    name: 'Northern Mariana Islands',
  },
  {
    code: '+596',
    icon: '🇲🇶',
    masks: [
      {
        mask: '(000)00-00-00',
      },
    ],
    name: 'Martinique',
  },
  {
    code: '+222',
    icon: '🇲🇷',
    masks: [
      {
        mask: '00-00-0000',
      },
    ],
    name: 'Mauritania',
  },
  {
    code: '+1',
    icon: '🇲🇸',
    masks: [
      {
        mask: '(664)000-0000',
      },
    ],
    name: 'Montserrat',
  },
  {
    code: '+356',
    icon: '🇲🇹',
    masks: [
      {
        mask: '0000-0000',
      },
    ],
    name: 'Malta',
  },
  {
    code: '+230',
    icon: '🇲🇺',
    masks: [
      {
        mask: '000-0000',
      },
    ],
    name: 'Mauritius',
  },
  {
    code: '+960',
    icon: '🇲🇻',
    masks: [
      {
        mask: '000-0000',
      },
    ],
    name: 'Maldives',
  },
  {
    code: '+265',
    icon: '🇲🇼',
    masks: [
      {
        mask: '1-000-000',
      },
      {
        mask: '0-0000-0000',
      },
    ],
    name: 'Malawi',
  },
  {
    code: '+52',
    icon: '🇲🇽',
    masks: [
      {
        mask: '00-00-0000',
      },
      {
        mask: '(000)000-0000',
      },
    ],
    name: 'Mexico',
  },
  {
    code: '+60',
    icon: '🇲🇾',
    masks: [
      {
        mask: '0-000-000',
      },
      {
        mask: '00-000-000',
      },
      {
        mask: '(000)000-000',
      },
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Malaysia',
  },
  {
    code: '+258',
    icon: '🇲🇿',
    masks: [
      {
        mask: '00-000-000',
      },
    ],
    name: 'Mozambique',
  },
  {
    code: '+264',
    icon: '🇳🇦',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Namibia',
  },
  {
    code: '+687',
    icon: '🇳🇨',
    masks: [
      {
        mask: '00-0000',
      },
    ],
    name: 'New Caledonia',
  },
  {
    code: '+227',
    icon: '🇳🇪',
    masks: [
      {
        mask: '00-00-0000',
      },
    ],
    name: 'Niger',
  },
  {
    code: '+672',
    icon: '🇳🇫',
    masks: [
      {
        mask: '300-000',
      },
    ],
    name: 'Norfolk Island',
  },
  {
    code: '+234',
    icon: '🇳🇬',
    masks: [
      {
        mask: '00-000-00',
      },
      {
        mask: '00-000-000',
      },
      {
        mask: '(000)000-0000',
      },
    ],
    name: 'Nigeria',
  },
  {
    code: '+505',
    icon: '🇳🇮',
    masks: [
      {
        mask: '0000-0000',
      },
    ],
    name: 'Nicaragua',
  },
  {
    code: '+31',
    icon: '🇳🇱',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Netherlands',
  },
  {
    code: '+47',
    icon: '🇳🇴',
    masks: [
      {
        mask: '(000)00-000',
      },
    ],
    name: 'Norway',
  },
  {
    code: '+977',
    icon: '🇳🇵',
    masks: [
      {
        mask: '00-000-000',
      },
    ],
    name: 'Nepal',
  },
  {
    code: '+674',
    icon: '🇳🇷',
    masks: [
      {
        mask: '000-0000',
      },
    ],
    name: 'Nauru',
  },
  {
    code: '+683',
    icon: '🇳🇺',
    masks: [
      {
        mask: '0000',
      },
    ],
    name: 'Niue',
  },
  {
    code: '+64',
    icon: '🇳🇿',
    masks: [
      {
        mask: '0-000-000',
      },
      {
        mask: '(000)000-000',
      },
      {
        mask: '(000)000-0000',
      },
    ],
    name: 'New Zealand',
  },
  {
    code: '+968',
    icon: '🇴🇲',
    masks: [
      {
        mask: '00-000-000',
      },
    ],
    name: 'Oman',
  },
  {
    code: '+507',
    icon: '🇵🇦',
    masks: [
      {
        mask: '000-0000',
      },
    ],
    name: 'Panama',
  },
  {
    code: '+51',
    icon: '🇵🇪',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Peru',
  },
  {
    code: '+689',
    icon: '🇵🇫',
    masks: [
      {
        mask: '00-00-00',
      },
    ],
    name: 'French Polynesia',
  },
  {
    code: '+675',
    icon: '🇵🇬',
    masks: [
      {
        mask: '(000)00-000',
      },
    ],
    name: 'Papua New Guinea',
  },
  {
    code: '+63',
    icon: '🇵🇭',
    masks: [
      {
        mask: '(000)000-0000',
      },
    ],
    name: 'Philippines',
  },
  {
    code: '+92',
    icon: '🇵🇰',
    masks: [
      {
        mask: '(000)000-0000',
      },
    ],
    name: 'Pakistan',
  },
  {
    code: '+48',
    icon: '🇵🇱',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Poland',
  },
  {
    code: '+508',
    icon: '🇵🇲',
    masks: [
      {
        mask: '00-0000',
      },
    ],
    name: 'Saint Pierre and Miquelon',
  },
  {
    code: '+870',
    icon: '🇵🇳',
    masks: [
      {
        mask: '000-000-000',
      },
    ],
    name: 'Pitcairn',
  },
  {
    code: '+1',
    icon: '🇵🇷',
    masks: [
      {
        mask: '(787) 000 0000',
      },
      {
        mask: '(939) 000 0000',
      },
    ],
    name: 'Puerto Rico',
  },
  {
    code: '+970',
    icon: '🇵🇸',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Palestine',
  },
  {
    code: '+351',
    icon: '🇵🇹',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Portugal',
  },
  {
    code: '+680',
    icon: '🇵🇼',
    masks: [
      {
        mask: '000-0000',
      },
    ],
    name: 'Palau',
  },
  {
    code: '+595',
    icon: '🇵🇾',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Paraguay',
  },
  {
    code: '+974',
    icon: '🇶🇦',
    masks: [
      {
        mask: '0000-0000',
      },
    ],
    name: 'Qatar',
  },
  {
    code: '+262',
    icon: '🇷🇪',
    masks: [
      {
        mask: '00000-0000',
      },
    ],
    name: 'Reunion',
  },
  {
    code: '+40',
    icon: '🇷🇴',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Romania',
  },
  {
    code: '+381',
    icon: '🇷🇸',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Serbia',
  },
  {
    code: '+7',
    icon: '🇷🇺',
    masks: [
      {
        mask: '(000)000-00-00',
      },
    ],
    name: 'Russia',
  },
  {
    code: '+250',
    icon: '🇷🇼',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Rwanda',
  },
  {
    code: '+966',
    icon: '🇸🇦',
    masks: [
      {
        mask: '0-000-0000',
      },
      {
        mask: '50-0000-0000',
      },
    ],
    name: 'Saudi Arabia',
  },
  {
    code: '+677',
    icon: '🇸🇧',
    masks: [
      {
        mask: '00000',
      },
      {
        mask: '000-0000',
      },
    ],
    name: 'Solomon Islands',
  },
  {
    code: '+248',
    icon: '🇸🇨',
    masks: [
      {
        mask: '0-000-000',
      },
    ],
    name: 'Seychelles',
  },
  {
    code: '+249',
    icon: '🇸🇩',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Sudan',
  },
  {
    code: '+46',
    icon: '🇸🇪',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Sweden',
  },
  {
    code: '+65',
    icon: '🇸🇬',
    masks: [
      {
        mask: '0000-0000',
      },
    ],
    name: 'Singapore',
  },
  {
    code: '+290',
    icon: '🇸🇭',
    masks: [
      {
        mask: '0000',
      },
    ],
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
  },
  {
    code: '+386',
    icon: '🇸🇮',
    masks: [
      {
        mask: '00-000-000',
      },
    ],
    name: 'Slovenia',
  },
  {
    code: '+47',
    icon: '🇸🇯',
    masks: [
      {
        mask: '(000)00-000',
      },
    ],
    name: 'Svalbard and Jan Mayen',
  },
  {
    code: '+421',
    icon: '🇸🇰',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Slovakia',
  },
  {
    code: '+232',
    icon: '🇸🇱',
    masks: [
      {
        mask: '00-000000',
      },
    ],
    name: 'Sierra Leone',
  },
  {
    code: '+378',
    icon: '🇸🇲',
    masks: [
      {
        mask: '0000-000000',
      },
    ],
    name: 'San Marino',
  },
  {
    code: '+221',
    icon: '🇸🇳',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Senegal',
  },
  {
    code: '+252',
    icon: '🇸🇴',
    masks: [
      {
        mask: '0-000-000',
      },
      {
        mask: '00-000-000',
      },
    ],
    name: 'Somalia',
  },
  {
    code: '+597',
    icon: '🇸🇷',
    masks: [
      {
        mask: '000-000',
      },
      {
        mask: '000-0000',
      },
    ],
    name: 'Suriname',
  },
  {
    code: '+211',
    icon: '🇸🇸',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'South Sudan',
  },
  {
    code: '+239',
    icon: '🇸🇹',
    masks: [
      {
        mask: '00-00000',
      },
    ],
    name: 'Sao Tome and Principe',
  },
  {
    code: '+503',
    icon: '🇸🇻',
    masks: [
      {
        mask: '00-00-0000',
      },
    ],
    name: 'El Salvador',
  },
  {
    code: '+1',
    icon: '🇸🇽',
    masks: [
      {
        mask: '(721)000-0000',
      },
    ],
    name: 'Sint Maarten',
  },
  {
    code: '+963',
    icon: '🇸🇾',
    masks: [
      {
        mask: '00-0000-000',
      },
    ],
    name: 'Syrian Arab Republic',
  },
  {
    code: '+268',
    icon: '🇸🇿',
    masks: [
      {
        mask: '00-00-0000',
      },
    ],
    name: 'Eswatini',
  },
  {
    code: '+1',
    icon: '🇹🇨',
    masks: [
      {
        mask: '(249)000-000',
      },
    ],
    name: 'Turks and Caicos Islands',
  },
  {
    code: '+235',
    icon: '🇹🇩',
    masks: [
      {
        mask: '00-00-00-00',
      },
    ],
    name: 'Chad',
  },
  {
    code: '+228',
    icon: '🇹🇬',
    masks: [
      {
        mask: '00-000-000',
      },
    ],
    name: 'Togo',
  },
  {
    code: '+992',
    icon: '🇹🇯',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Tajikistan',
  },
  {
    code: '+690',
    icon: '🇹🇰',
    masks: [
      {
        mask: '0000',
      },
    ],
    name: 'Tokelau',
  },
  {
    code: '+670',
    icon: '🇹🇱',
    masks: [
      {
        mask: '000-0000',
      },
      {
        mask: '770-00000',
      },
      {
        mask: '780-00000',
      },
    ],
    name: 'Timor-Leste',
  },
  {
    code: '+993',
    icon: '🇹🇲',
    masks: [
      {
        mask: '0-000-0000',
      },
    ],
    name: 'Turkmenistan',
  },
  {
    code: '+216',
    icon: '🇹🇳',
    masks: [
      {
        mask: '00-000-000',
      },
    ],
    name: 'Tunisia',
  },
  {
    code: '+676',
    icon: '🇹🇴',
    masks: [
      {
        mask: '00000',
      },
    ],
    name: 'Tonga',
  },
  {
    code: '+90',
    icon: '🇹🇷',
    masks: [
      {
        mask: '(000)000-0000',
      },
    ],
    name: 'Turkey',
  },
  {
    code: '+1',
    icon: '🇹🇹',
    masks: [
      {
        mask: '(868)000-0000',
      },
    ],
    name: 'Trinidad and Tobago',
  },
  {
    code: '+688',
    icon: '🇹🇻',
    masks: [
      {
        mask: '20000',
      },
      {
        mask: '900000',
      },
    ],
    name: 'Tuvalu',
  },
  {
    code: '+886',
    icon: '🇹🇼',
    masks: [
      {
        mask: '0000-0000',
      },
      {
        mask: '0-0000-0000',
      },
    ],
    name: 'Taiwan',
  },
  {
    code: '+255',
    icon: '🇹🇿',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Tanzania, United Republic of Tanzania',
  },
  {
    code: '+380',
    icon: '🇺🇦',
    masks: [
      {
        mask: '(00)000-00-00',
      },
    ],
    name: 'Ukraine',
  },
  {
    code: '+256',
    icon: '🇺🇬',
    masks: [
      {
        mask: '(000)000-000',
      },
    ],
    name: 'Uganda',
  },
  {
    code: '+1',
    icon: '🇺🇸',
    masks: [
      {
        mask: '(000)000-0000',
      },
    ],
    name: 'United States',
  },
  {
    code: '+598',
    icon: '🇺🇾',
    masks: [
      {
        mask: '0-000-00-00',
      },
    ],
    name: 'Uruguay',
  },
  {
    code: '+998',
    icon: '🇺🇿',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Uzbekistan',
  },
  {
    code: '+39',
    icon: '🇻🇦',
    masks: [
      {
        mask: '06 69800000',
      },
    ],
    name: 'Holy See (Vatican City State)',
  },
  {
    code: '+1',
    icon: '🇻🇨',
    masks: [
      {
        mask: '(784)000-0000',
      },
    ],
    name: 'Saint Vincent and the Grenadines',
  },
  {
    code: '+58',
    icon: '🇻🇪',
    masks: [
      {
        mask: '(000)000-0000',
      },
    ],
    name: 'Venezuela, Bolivarian Republic of Venezuela',
  },
  {
    code: '+1',
    icon: '🇻🇬',
    masks: [
      {
        mask: '(284)000-0000',
      },
    ],
    name: 'Virgin Islands, British',
  },
  {
    code: '+1',
    icon: '🇻🇮',
    masks: [
      {
        mask: '(340)000-0000',
      },
    ],
    name: 'Virgin Islands, U.S.',
  },
  {
    code: '+84',
    icon: '🇻🇳',
    masks: [
      {
        mask: '00-0000-000',
      },
      {
        mask: '(000)0000-000',
      },
    ],
    name: 'Vietnam',
  },
  {
    code: '+678',
    icon: '🇻🇺',
    masks: [
      {
        mask: '00000',
      },
      {
        mask: '00-00000',
      },
    ],
    name: 'Vanuatu',
  },
  {
    code: '+681',
    icon: '🇼🇫',
    masks: [
      {
        mask: '00-0000',
      },
    ],
    name: 'Wallis and Futuna',
  },
  {
    code: '+685',
    icon: '🇼🇸',
    masks: [
      {
        mask: '00-0000',
      },
    ],
    name: 'Samoa',
  },
  {
    code: '+383',
    icon: '🇽🇰',
    masks: [
      {
        mask: '00-000-000',
      },
      {
        mask: '000-000-000',
      },
    ],
    name: 'Kosovo',
  },
  {
    code: '+967',
    icon: '🇾🇪',
    masks: [
      {
        mask: '0-000-000',
      },
      {
        mask: '00-000-000',
      },
      {
        mask: '000-000-000',
      },
    ],
    name: 'Yemen',
  },
  {
    code: '+262',
    icon: '🇾🇹',
    masks: [
      {
        mask: '00000-0000',
      },
    ],
    name: 'Mayotte',
  },
  {
    code: '+27',
    icon: '🇿🇦',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'South Africa',
  },
  {
    code: '+260',
    icon: '🇿🇲',
    masks: [
      {
        mask: '00-000-0000',
      },
    ],
    name: 'Zambia',
  },
  {
    code: '+263',
    icon: '🇿🇼',
    masks: [
      {
        mask: '0-000000',
      },
    ],
    name: 'Zimbabwe',
  },
];
