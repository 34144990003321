import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownPosition } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
  PDFDocumentProxy,
  PdfViewerModule,
  ZoomScale,
} from 'ng2-pdf-viewer';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { featureFlag } from '../../../../environments/environment';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { PdfPageControllerComponent } from '../pdf-page-controller/pdf-page-controller.component';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  standalone: true,
  imports: [
    AngularSvgIconModule,
    CommonModule,
    NgbDropdownModule,
    NgxSpinnerModule,
    PdfPageControllerComponent,
    PdfViewerModule,
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfViewerComponent
  implements OnChanges, OnInit, AfterViewChecked, OnDestroy
{
  /** The bottom position in px of the controller */
  @Input() controllerBottomPosition?: string;
  @Input() page?: number | null;
  @Input() pdfFile: any;
  @Input() showDownload = false;
  @Input() pdfViewport: PdfViewport = {
    scale: 1,
    fit: 'page-fit',
  };
  @Output() downloaded = new EventEmitter<void>();
  @Output() clickPreview = new EventEmitter<void>();
  themeList?: ThemeList;
  @ViewChild(PdfPageControllerComponent, {
    read: ElementRef<HTMLElement>,
  })
  pdfPageControllerElRef?: ElementRef<HTMLElement>;

  _featureFlag = featureFlag;
  _page: WritableSignal<number> = signal(1);
  compSubs: Subscription = new Subscription();
  dropdownPosition: DropdownPosition = 'auto';
  httpHeaders;
  isLoading = true;
  pdfDocProxy?: PDFDocumentProxy;
  totalPage = 0;

  private isControllerPositionChange = false;
  private cdRef = inject(ChangeDetectorRef);
  private ngxSpinner = inject(NgxSpinnerService);

  constructor(
    public themeService: ThemeService,
    private authenticationService: AuthenticationService,
    private elRef: ElementRef<HTMLElement>,
  ) {
    this.httpHeaders = this.authenticationService.httpHeader;
    const subs = this.themeService.data.subscribe({
      next: (theme) => {
        this.themeList = theme;
      },
    });
    this.compSubs.add(subs);
    effect(() => {
      if (!this.pdfDocProxy) {
        return;
      }
      this.isLoading = true;
      this.pdfDocProxy.getPage(this._page()).then(() => {
        this.isLoading = false;
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pdfFile']) {
      this.httpHeaders = this.authenticationService.httpHeader;
    }
    if (changes['page']) {
      const page = changes['page'].currentValue as
        | number
        | null
        | undefined;
      this._page.set(page || 1);
    }
    if (changes['controllerBottomPosition']) {
      this.isControllerPositionChange = true;
    }
  }

  ngOnInit(): void {
    this.ngxSpinner.show('loadingPdfViewer');
  }

  ngAfterViewChecked(): void {
    if (this.isControllerPositionChange) {
      this.isControllerPositionChange = false;
      this.dropdownPosition =
        (this.pdfPageControllerElRef?.nativeElement.offsetTop || 0) >
        this.elRef.nativeElement.clientHeight / 2
          ? 'top'
          : 'bottom';
    }
  }

  ngOnDestroy(): void {
    this.compSubs.unsubscribe();
  }

  changePage(pageCount: number): void {
    const page = this._page();
    if (page == null) {
      console.warn('The current page is undefined yet');
      return;
    }
    this.toPage(page + pageCount);
  }

  onControlScaleChange(scale: number | PdfViewport['fit']): void {
    if (typeof scale === 'number') {
      this.pdfViewport.scale = scale;
      this.pdfViewport.fit = null;
    } else if (typeof scale === 'string') {
      this.pdfViewport.scale = 1;
      this.pdfViewport.fit = scale as PdfViewport['fit'];
    }
  }

  onLoadPdfComplete(pdf: PDFDocumentProxy): void {
    this.pdfDocProxy = pdf;
    this.totalPage = pdf.numPages;
    this.isLoading = false;
    this.cdRef.detectChanges();
  }

  onPageRendered(e: Partial<CustomEvent> & { [k: string]: any }) {
    this.pdfViewport.renderedHeight = e['source']?.viewport?.height;
    this.pdfViewport.renderedScale =
      e['source']?.viewport?.scale || 1;
  }

  toPage(page: number): void {
    if (this.isLoading) {
      return;
    }
    let finalPage = page;
    if (finalPage < 1) {
      finalPage = 1;
    } else if (finalPage > this.totalPage) {
      finalPage = this.totalPage;
    }
    this._page.set(page);
  }

  isFileString(): boolean {
    return typeof this.pdfFile === 'string';
  }
}

export interface PdfViewport {
  fit?: ZoomScale | null;
  renderedHeight?: number;
  renderedScale?: number;
  scale: number;
}
