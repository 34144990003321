import { HttpHeaders } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { OtpSmsService } from '../../../modules/profile/component/setting-otp-sms/otp-sms.service';
import { SetRef } from '../../../store/otp/otp.actions';
import { AlertService } from '../../../core/services/alert.service';
import { OtpMailService } from '../../../modules/profile/component/setting-otp/otp-mail.service';
import { ThemeList } from '../../service/theme.service';
import { ErrorNotification } from 'src/app/store/memo/memo.actions';

@Component({
  selector: 'app-otp-modal',
  templateUrl: './otp-modal.component.html',
  styleUrls: ['./otp-modal.component.scss'],
})
export class OtpModalComponent implements OnInit {
  @Input() isSaved: boolean;
  @Input() otp: string | any = '';
  // I don't inherit class ApprovalRequestBaseComponent because there are cases that component is
  //   used when it is not related to signing memo. Ex. it is used in 'app-select-sign-method-modal'
  //   which is used in user settings page.
  @Input() memo_id: number | undefined | null = null;
  @Output() otpChange = new EventEmitter<string>();
  @Output() closeModal = new EventEmitter<void>();
  @Input() header: any;

  @ViewChild('otpGoogleAuthentication', { static: false })
  otpGoogleAuthentication: ElementRef;
  @ViewChild('otpEmail', { static: false })
  otpEmail: ElementRef;
  @ViewChild('otpPhoneNumber', { static: false })
  otpPhoneNumber: ElementRef;

  // TODO: It has no assignment. Need implementation.
  themeList?: ThemeList;
  timeLeft = 300;
  interval: any;

  otpType = 'totp';
  userPhoneNumber: string;
  userEmail: string;

  refDisPlay: string;
  isLoadingOTP: boolean;

  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '45px',
      height: '50px',
    },
    inputClass: 'each_input',
  };

  constructor(
    public modalService: NgbModal,
    private store: Store,
    private otpSmsService: OtpSmsService,
    private otpMailService: OtpMailService,
    private alert: AlertService,
  ) {}

  ngOnInit(): void {
    this.store.subscribe((s) => {
      this.otpType = s.auth?.user_info?.otp_type;
      this.userPhoneNumber = s.auth?.user_info?.phone_number;
      this.userEmail = s.auth?.user_info?.email;
    });
  }

  getHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', this.header);
  }

  openModal(): void {
    if (this.otpType === 'totp') {
      this.openTOTP();
      return;
    }
    this.sendOTP();
  }

  openTOTP() {
    this.modalService.open(this.otpGoogleAuthentication, {
      backdrop: 'static',
      size: 'size-smax56',
      keyboard: false,
      centered: true,
    });
  }

  onOtpChange(otp: number | string): void {
    this.otp = otp;
  }

  sendOTP() {
    this.isLoadingOTP = true;
    let header = null;
    if (this.header) {
      header = this.getHeader();
    }
    let service = null;
    let data = null;

    if (this.otpType === 'sms') {
      service = this.otpSmsService;
      data = {
        memo_id: this.memo_id,
        resend: false,
        detail: null,
      };
    } else {
      service = this.otpMailService;
    }
    service.send(data, header).subscribe(
      (res: any) => {
        this.refDisPlay = res.ref_display;
        this.store.dispatch(
          new SetRef({
            ref: res.ref,
            refDisplay: this.refDisPlay,
          }),
        );
        this.isLoadingOTP = false;
      },
      (error: any) => {
        this.isLoadingOTP = false;
        if (error.status === 400 && error.error) {
          const firstKey = Object.keys(error.error)[0];
          try {
            const errorObj = JSON.parse(error.error[firstKey]);
            if ('detail' in errorObj) {
              this.alert.error(errorObj.detail);
            } else {
              this.alert.error(error.error[firstKey]);
            }
          } catch (e) {
            this.alert.error(error.error[firstKey]);
          }
        } else {
          this.store.dispatch(new ErrorNotification(error));
        }
      },
    );
    if (this.otpType === 'sms') {
      this.openSMSOTP();
    } else {
      this.openEmailOTP();
    }
  }

  openSMSOTP() {
    this.modalService.open(this.otpPhoneNumber, {
      backdrop: 'static',
      size: 'size-smax56',
      keyboard: false,
      centered: true,
    });
    this.startTimer();
  }

  openEmailOTP() {
    this.modalService.open(this.otpEmail, {
      backdrop: 'static',
      size: 'size-smax56',
      keyboard: false,
      centered: true,
    });
    this.startTimer();
  }

  startTimer(): void {
    this.pauseTimer();
    this.timeLeft = 300;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.pauseTimer();
      }
    }, 1000);
  }

  pauseTimer(): void {
    clearInterval(this.interval);
  }

  resend(): void {
    this.isLoadingOTP = true;
    let header = null;
    if (this.header) {
      header = this.getHeader();
    }
    let service = null;
    let data = null;

    if (this.otpType === 'sms') {
      service = this.otpSmsService;
      data = {
        memo_id: this.memo_id,
        resend: true,
        detail: null,
      };
    } else {
      service = this.otpMailService;
    }
    service.send(data, header).subscribe(
      (res: any) => {
        this.refDisPlay = res.ref_display;
        this.store.dispatch(
          new SetRef({
            ref: res.ref,
            refDisplay: this.refDisPlay,
          }),
        );
        this.isLoadingOTP = false;
        this.startTimer();
        this.otp = null;
      },
      (error: any) => {
        this.isLoadingOTP = false;
        if (error.status === 400 && error.error) {
          const firstKey = Object.keys(error.error)[0];
          try {
            const errorObj = JSON.parse(error.error[firstKey]);
            if ('detail' in errorObj) {
              this.alert.error(errorObj.detail);
            } else {
              this.alert.error(error.error[firstKey]);
            }
          } catch (e) {
            this.alert.error(error.error[firstKey]);
          }
        } else {
          this.store.dispatch(new ErrorNotification(error));
        }
      },
    );
  }

  close(): void {
    this.modalService.dismissAll();
    this.otp = null;
    this.closeModal.emit();
  }

  get invalidOTP(): boolean {
    return !this.otp || String(this.otp).length < 6;
  }

  get hiddenNumber(): string {
    return (
      this.userPhoneNumber.substring(0, 3) +
      '****' +
      this.userPhoneNumber.substring(7)
    );
  }

  get hiddenEmail(): string {
    if (!this.userEmail) {
      return '';
    }
    const email_length = this.userEmail.split('@')[0].length;
    const email_slice_index = (email_length * 20) / 100;
    return (
      this.userEmail.slice(0, email_slice_index) +
      '****@' +
      this.userEmail.split('@')[1]
    );
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
