<ng-template #certificateStatusTemplate
    let-certStatus
    let-lawSection="lawSection">
  <div class="validity-status align-items-baseline"
      [ngSwitch]="certStatus">
    <ng-container *ngSwitchCase="'valid'">
      <span class="fas fa-check-circle text-success"></span>
      <label class="text-success">{{ 'CERTIFICATE.VALID' | translate }}</label>
    </ng-container>
    <ng-container *ngSwitchCase="'expired'">
      <span class="fas fa-times-circle text-danger"></span>
      <label class="text-danger">{{ (lawSection === '26' ?
        'CERTIFICATE.Expired system will automatically create a new one when user approve the document' :
        'CERTIFICATE.EXPIRED') | translate }}</label>
    </ng-container>
    <ng-container *ngSwitchCase="'revoked'">
      <span class="fas fa-times-circle text-danger"></span>
      <label class="text-danger">{{ 'CERTIFICATE.REVOKED' | translate }}</label>
    </ng-container>
    <ng-container *ngSwitchCase="'not_created'">
      <span class="fas fa-times-circle text-danger"></span>
      <label class="text-danger">
        <span *ngIf="lawSection === '26'; else defaultNotCreateWording">
          {{ 'CERTIFICATE.USER-NO-CERT-SYSTEM-CREATE' | translate }}
        </span>
        <ng-template #defaultNotCreateWording>
          <span>
            {{ 'CERTIFICATE.NOT-CREATED' | translate }}
          </span>
        </ng-template>
      </label>
    </ng-container>
  </div>
</ng-template>
<!-- ถ้าระบบเปิดให้เลือกแค่ 26 หรือ 28 อย่างเดียว ก็ไม่ต้องมี radio ใ้ห้ผู้ใช้กด-->
<ng-container *ngIf="ddocLawSection && ddoc_enable_feature_26 && !ddoc_enable_feature_28">
  <div class="float-right mt-1">
    <ng-container *ngTemplateOutlet="certificateStatusTemplate;
      context: { $implicit: certStatusSec26, lawSection: '26' }">
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="ddocLawSection && !ddoc_enable_feature_26 && ddoc_enable_feature_28">
  <div class="float-right mt-1">
    <ng-container *ngTemplateOutlet="certificateStatusTemplate; context: { $implicit: certStatusSec28 }">
    </ng-container>
  </div>
</ng-container>
<div class="radio-section mt-3" *ngIf="ddocLawSection && ddoc_enable_feature_26 && ddoc_enable_feature_28">
  <div class="flex-lg-nowrap py-2">
    <div class="radio-option">
      <div class="icon-radio ms-2"
          (click)="onRadioClick('26')">
        <span class="circle" [class.disabled]="!loaAllowEdit"></span>
        <span *ngIf="ddocLawSection.lawSection === '26'"
            class="dot" [class.disabled]="!loaAllowEdit"></span>
      </div>
      <label class="mb-0">
        {{ 'LOA.DIGITAL-SIGNATURE-SEC-26' | translate }}
      </label>
      <!-- TODO - Make it to popup and add content in future sprint -->
      <span class="fal fa-info-circle icon-info d-none d-md-inline invisible"></span>
    </div>
    <ng-container *ngTemplateOutlet="certificateStatusTemplate;
        context: { $implicit: certStatusSec26, lawSection: '26' }">
    </ng-container>
  </div>
  <div class="py-2">
    <div class="radio-option">
      <div class="icon-radio ms-2"
          (click)="onRadioClick('28')">
        <span class="circle" [class.disabled]="(certStatusSec28 !== 'valid' && !freeLawSelection) || !loaAllowEdit"></span>
        <span *ngIf="ddocLawSection.lawSection === '28'"
            class="dot" [class.disabled]="(certStatusSec28 !== 'valid' && !freeLawSelection) || !loaAllowEdit"></span>
      </div>
      <label class="mb-0">
        {{ 'LOA.DIGITAL-SIGNATURE-SEC-28' | translate }}
      </label>
      <!-- TODO - Make it to popup and add content in future sprint -->
      <span class="fal fa-info-circle icon-info invisible"></span>
    </div>
    <ng-container *ngTemplateOutlet="certificateStatusTemplate; context: { $implicit: certStatusSec28 }">
    </ng-container>
  </div>
</div>
