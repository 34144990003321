import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  CeleryImportState,
  CeleryTaskStatus,
} from '../../models/drf-response.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageTypesUploadMemoService } from '../../../modules/manage-types-upload-memo/shared/manage-types-upload-memo.service';
import { buildErrorHTMLMessage } from '../../utils/common.util';
import { ApiService } from 'src/app/core/http/api.service';
import { Idle } from '@ng-idle/core';
import { Subscription } from 'rxjs';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-export-excel-modal',
  templateUrl: './export-excel-modal.component.html',
  styleUrls: ['./export-excel-modal.component.scss'],
})
export class ExportExcelModalComponent implements OnDestroy {
  @ViewChild('downloadModal', { static: true })
  downloadModal: ElementRef;
  @Input() checkPage: string;

  celeryImportState = CeleryImportState;

  sendingTaskInterval: any;
  sendingTaskDetail: CeleryTaskStatus | any;
  filePath: string;
  taskId: string;

  // refresh less than 1-2 secs is too much. it should be more than 5 secs.
  @Input() timeout = 5000;
  @Input() resultTitle: string;
  @Input() isExportGoogle = false;
  @Output() uploadSuccess = new EventEmitter();
  @Output() uploadFailed = new EventEmitter();
  subscription: Subscription[] = [];
  themeList?: ThemeList;
  percentDownload?: number | any;

  constructor(
    private apiService: ManageTypesUploadMemoService,
    private httpClientService: ApiService,
    public modalService: NgbModal,
    private idle: Idle,
    private themeService: ThemeService,
  ) {
    this.subscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
      }),
    );
  }

  showModal(taskId: string): void {
    this.percentDownload = 0;
    if (taskId == null) {
      console.error('taskId cannot be empty');
      return;
    }

    this.cancelSendingTask();
    this.filePath = '';
    this.taskId = taskId;
    this.sendingTaskDetail = {
      _state: CeleryImportState.Pending,
    };
    this.subscribe(this.taskId);
    this.modalService.open(this.downloadModal, {
      backdrop: 'static',
      size: 'md',
      keyboard: false,
      centered: true,
    });
  }

  showUserModal(taskId: string): void {
    this.percentDownload = 0;
    if (taskId == null) {
      console.error('taskId cannot be empty');
      return;
    }

    this.cancelSendingTask();
    this.filePath = '';
    this.taskId = taskId;
    this.sendingTaskDetail = {
      _state: CeleryImportState.Pending,
    };
    this.subscribe(this.taskId);
    this.modalService.open(this.downloadModal, {
      backdrop: 'static',
      size: 'md',
      keyboard: false,
      centered: true,
    });
  }

  subscribe(taskId: string): void {
    this.sendingTaskInterval = undefined;
    this.sendingTaskDetail = {
      _state: CeleryImportState.Pending,
    };

    this.subscribeSendingTaskStatus(taskId);
  }

  subscribeSendingTaskStatus(taskId: string): void {
    this.sendingTaskInterval = setInterval(() => {
      // send signal to Idle object that user is using website
      //   because report may take a long to time to process.
      this.idle.interrupt();

      if (
        this.sendingTaskDetail == null ||
        (this.sendingTaskDetail._state !==
          CeleryImportState.Success &&
          this.sendingTaskDetail._state !== CeleryImportState.Failure)
      ) {
        const subscription = this.apiService
          .getCeleryTaskStatus(taskId)
          .subscribe((res: any) => {
            // update progress
            this.sendingTaskDetail = res;
            // unsubscribe when success or failure
            if (
              res._state === CeleryImportState.Success ||
              res._state === CeleryImportState.Failure
            ) {
              this.filePath = this.sendingTaskDetail.excel;
            }

            if (res._state === CeleryImportState.Failure) {
              this.uploadFailed.emit(res.detail);
              res.detail = buildErrorHTMLMessage(res.detail);
            }
            if (res._state === CeleryImportState.Success) {
              this.percentDownload = 100;
              if (this.isExportGoogle) {
                this.uploadSuccess.emit();
                return;
              }
              this.downloadCSV();
            }
          });
        this.subscription.push(subscription);
      }
    }, this.timeout);
  }

  cancelSendingTask(): void {
    if (this.sendingTaskInterval) {
      clearInterval(this.sendingTaskInterval);
    }
  }

  close() {
    this.cancelSendingTask();
    this.modalService.dismissAll();
  }

  replaceUnderscore(text: string, withChar = ' '): string {
    return text.split('_').join(withChar);
  }

  downloadCSV() {
    if (!this.filePath) {
      this.uploadSuccess.emit();
      setTimeout(() => this.modalService.dismissAll(), 2000);
      return;
    }

    const fileName = this.filePath.split('/').pop()?.split('?')[0];
    if (!fileName) {
      console.error('Invalid file name');
      return;
    }

    const request = this.httpClientService.download(this.filePath);
    const subscription = request.subscribe({
      next: (response: unknown) => {
        const blob = new Blob([response as Blob], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        setTimeout(() => this.modalService.dismissAll(), 2000);
      },
      error: (error: HttpErrorResponse) =>
        console.error(error.status),
    });

    this.subscription.push(subscription);
  }

  ngOnDestroy(): void {
    this.cancelSendingTask();
    if (this.subscription) {
      this.subscription?.forEach((item) => {
        try {
          item.unsubscribe();
        } catch (e) {
          console.error(e);
        }
      });
    }
  }

  get resultKeys(): string[] {
    if (
      !this.sendingTaskDetail.rows ||
      !this.sendingTaskDetail.rows.length
    ) {
      return [];
    }

    return Object.keys(this.sendingTaskDetail.rows[0]);
  }

  get currentStateProgressType(): string {
    if (this.sendingTaskDetail._state === CeleryImportState.Success) {
      this.sendingTaskDetail.percent = 100;
      return 'success-custom';
    }
    if (this.sendingTaskDetail._state === CeleryImportState.Failure) {
      return 'danger';
    }
    return 'info-custom';
  }
}
