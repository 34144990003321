<div class="font-charmonman d-none">&#8203;</div>
<ng-container [ngTemplateOutlet]="headerDetail ? headerDetail.templateRef: headerDefault"></ng-container>
<ng-template #headerDefault></ng-template>
<div class="my-3">
  <div class="m-0 fw-medium text-black-pantone pb-l8">
    {{ 'PROFILE.Full Name'| translate }}
  </div>
  <input class="form-control"
      [readOnly]="selectOnly"
      [(ngModel)]="_fullName"
      (ngModelChange)="onFullNameChange()">
</div>
<app-slide-tab-bar [tabs]="tabs"
    [showBadge]="false"
    [selectedTab]="selectedSignature"
    (selectedTabChange)="onSelectedTabChange($event)">
</app-slide-tab-bar>
<ng-container [ngSwitch]="selectedSignature">
  <div class="d-block mt-u0 fw-medium text-black-pantone pb-l8">
    {{ 'MEMOS.Select type of font' | translate }}
  </div>
  <div *ngSwitchCase="'full'"
      class="box-select-font-signature">
    <div *ngFor="let fontType of fontOptions; let i = index"
        class="d-flex justify-content-between align-items-center p-2">
      <div class="form-check">
        <input class="form-check-input"
            type="radio"
            name="font"
            [id]="'font-' + i"
            (click)="onTextSignatureSelect('full', fontType.id)"
            [checked]="fontType.id === 1">
        <label class="form-check-label"
            [for]="'font-' + i">
          {{ fontType.name }}
        </label>
      </div>
      <canvas class="canvas-signature col-12"
          [id]="'canvas-fullname-' + (i+1)">
      </canvas>
    </div>
  </div>

  <div *ngSwitchCase="'abbreviate'"
      class="box-select-font-signature">
    <div *ngFor="let fontType of fontOptions; let i=index"
        class="d-flex justify-content-between align-items-center p-2">
      <div class="form-check">
        <input class="form-check-input"
            type="radio"
            name="font"
            [id]="'font-' + i"
            (click)="onTextSignatureSelect('abbreviate' ,fontType.id)"
            [checked]="fontType.id === 1">
        <label class="form-check-label"
            [for]="'font-' + i">
          {{ fontType.name }}
        </label>
      </div>
      <canvas class="canvas-signature col-12"
          [id]="'canvas-abbreviated-name-' + (i+1)">
      </canvas>
    </div>
  </div>
</ng-container>
<div class="d-flex justify-content-end mt-4"
    [ngClass]="{'pt-2 mb-btn-bottom': !isModal}">
  <button type="button"
      class="btn btn-std btn-std-2ry fs-base-l0 fw-semibold"
      [ngClass]="isModal ? 'btn-h-sm-res fs-base-l4 mx-1 py-base-l8 px-base-l4 btn-width': 'me-l5'"
      (click)="onCancelClick()">
    {{ cancelText | translate }}
  </button>
  <button type="button"
      [ladda]="isLoading"
      class="btn btn-std btn-std-prim fs-base-l0 fw-semibold"
      [ngClass]="{'btn-h-sm-res fs-base-l4 mx-1 py-base-l8 px-base-l4 btn-width': isModal}"
      (click)="onConfirmClick()">
    {{ confirmText | translate }}
  </button>
</div>