import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-preview-pdf',
  templateUrl: './preview-pdf.component.html',
  styleUrls: ['./preview-pdf.component.scss'],
})
export class PreviewPdfComponent implements AfterViewInit {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  src: any =
    'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
  closeResult: any = '';
  @ViewChild('content') ngModel: NgModel;
  @Input() printItem: any;
  @Output() printItemChange = new EventEmitter();

  constructor(private modalService: NgbModal) {}
  ngAfterViewInit(): void {
    this.open(this.ngModel);
  }

  open(content: any) {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.printItemChange.emit(null);
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(
            reason,
          )}`;
          this.printItemChange.emit(null);
        },
      );
  }

  async print(): Promise<void> {
    const blob = await fetch(this.src).then((r) => r.blob());
    const blobUrl = window.URL.createObjectURL(blob);
    const iframe: any = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }
  private getDismissReason(reason: any) {}
}
