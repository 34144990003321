import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SIGNATURE_COLOR_LIST } from '../../../../modules/memos/service/upload-memo.constants';
import { CanvasWhiteboardComponent } from '@preeco-privacy/ng2-canvas-whiteboard';

@Component({
  selector: 'app-signature-sign-now',
  templateUrl: './signature-sign-now.component.html',
  styleUrls: ['./signature-sign-now.component.scss'],
})
export class SignatureSignNowComponent implements AfterViewInit {
  @Input() cancelText = 'APPROVAL.BACK';
  @Input() isModal = false;
  @Output() back = new EventEmitter<void>();
  @Output() next = new EventEmitter<File>();

  signatureColorList = SIGNATURE_COLOR_LIST;
  selectedColor = this.signatureColorList[0];

  signatureThickness = 10;
  showPopupLine = false;
  showColorPicker = false;

  isLoading = false;
  showBtn = false;

  @ViewChild('canvasWhiteboard', { static: false })
  canvasWhiteboard: CanvasWhiteboardComponent;

  ngAfterViewInit(): void {
    this.showBtn = true;
  }

  closeTool(): void {
    this.showColorPicker = false;
    this.showPopupLine = false;
  }

  onEditSizeLine(): void {
    this.showColorPicker = false;
    this.showPopupLine = !this.showPopupLine;
  }

  onClickColorPicker(): void {
    this.showPopupLine = false;
    this.showColorPicker = !this.showColorPicker;
  }

  onUndoCanvas(): void {
    this.closeTool();
    this.canvasWhiteboard.undoLocal();
  }

  onRedoCanvas(): void {
    this.closeTool();
    this.canvasWhiteboard.redoLocal();
  }

  onClearCanvas(): void {
    this.closeTool();
    this.canvasWhiteboard.clearCanvas();
  }

  resizeLine(): void {
    this.getSignatureThickness(this.signatureThickness);
  }

  getSignatureThickness(canvasWidth: number): number {
    return this.signatureThickness || 10;
  }

  resetSizeLine(): void {
    this.signatureThickness = 10;
  }

  onSelectedColor(color: string): void {
    this.selectedColor = color;
  }

  changeComplete(event: { color: { hex: string } }): void {
    this.selectedColor = event.color.hex;
  }

  save(): void {
    this.canvasWhiteboard.saveLocal();
  }

  onSave(event: any): void {
    this.isLoading = true;
    this.canvasWhiteboard.generateCanvasBlob((blob: Blob) => {
      const signImage = new File([blob], 'image', {
        type: blob.type,
      });
      this.isLoading = false;
      this.next.emit(signImage);
    });
  }
}
