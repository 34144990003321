<div ngbDropdown
    class="d-inline-block dropdown"
    [ngClass]="{
      'border': borderVisible
    }">
  <button type="button"
      class="btn btn-outline-primary"
      id="countryCodesDropdown"
      ngbDropdownToggle>
    {{ selectedCountryCode.icon }}
  </button>
  <div ngbDropdownMenu aria-labelledby="countryCodesDropdown">
    <div class="search-input">
      <i class="far fa-search"></i>
      <input type="text"
          placeholder="Search Country"
          [(ngModel)]="search"
          (ngModelChange)="onSearchCountry($event)">
    </div>
    <button *ngFor="let countryCode of countryCodes"
        (click)="selectedCountryCode = countryCode"
        ngbDropdownItem>
      {{ countryCode.icon }} {{ countryCode.name }}
    </button>
  </div>
</div>
<div class="phone-input-group"
    [ngClass]="{
        'border': borderVisible && !invalid,
        'border-danger': invalid
    }">
  <span>{{selectedCountryCode.code}}</span>
  <input type="tel"
      [imask]="selectedCountryCode.masks ?
        { mask: selectedCountryCode.masks } :
        defaultMaskConfig
      "
      [placeholder]="
        placeholder?.length ?
          placeholder :
          'Please type your phone number'
      "
      [(ngModel)]="phoneNumber"
      (ngModelChange)="phoneDeBouncer.next($event)" />
</div>