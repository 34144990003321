import { Action, State, StateContext } from '@ngxs/store';
import { MemoPreview } from './memo-preview.model';
import { Injectable } from '@angular/core';
import {
  CloseMemoPreview,
  NavigateToMemoDetail,
  OpenMemoPreview,
} from './memo-preview.actions';
import { Router } from '@angular/router';

@State<MemoPreview>({
  name: 'memoPreview',
  defaults: {
    isPreview: false,
    pdfFile: null,
    memo: null,
    queryParams: null,
    activeTab: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class MemoPreviewState {
  constructor(private router: Router) {}

  @Action(OpenMemoPreview)
  openMemoPreview(
    ctx: StateContext<MemoPreview>,
    action: OpenMemoPreview,
  ) {
    ctx.patchState({
      ...action.payload,
      isPreview: true,
    });
  }

  @Action(CloseMemoPreview)
  closeMemoPreview(ctx: StateContext<MemoPreview>) {
    ctx.patchState({
      isPreview: false,
      pdfFile: null,
      memo: null,
      activeTab: null,
    });
  }

  @Action(NavigateToMemoDetail)
  navigateToMemoDetail(
    ctx: StateContext<MemoPreview>,
    { activeTab }: any,
  ) {
    const currentState = ctx.getState();
    const memoId = currentState.memo?.id;
    const queryParams = currentState.queryParams;
    const navigationPath = activeTab
      ? ['memos', 'preview', memoId, activeTab]
      : ['memos', 'preview', memoId];

    this.router.navigate(navigationPath, { queryParams });

    ctx.patchState({
      isPreview: false,
      pdfFile: null,
      memo: null,
      activeTab: activeTab,
    });
  }
}
